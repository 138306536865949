<template>
  <div
    class="page-menu"
    :class="{ 'highlight-matched-route': !highlightExactRoute }"
  >
    <div v-for="menuItem in menu" :key="menuItem._id">
      <div class="page-menu-category" v-if="menuItem.type == 'category'">
        <label>{{ menuItem.name }}</label>
        <!-- START: Group Fields -->
        <PageSubMenu :menu="menuItem.menuItems"></PageSubMenu>
        <!-- END: Group Fields -->
      </div>
      <router-link
        v-else
        class="page-menu-item"
        :to="menuItem.path"
        @click="menuClicked(menuItem)"
      >
        <unicon v-if="menuItem.icon" :name="menuItem.icon"></unicon>
        <span>{{ menuItem.name }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageMenu",
  props: {
    menu: {
      type: Array,
      required: true,
    },
    highlightExactRoute: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    PageSubMenu: () => import("./PageMenu.vue"),
  },
  methods: {
    menuClicked(item) {
      this.$emit("menuClicked", item);
    },
  },
};
</script>

<style scoped lang="scss">
.page-menu-category {
  margin-bottom: 2.5rem;

  label {
    display: block;
    color: lighten($paragraphColor, 15%);
    font-weight: $mediumFontWeight;
    font-size: $smallestFontSize;
    margin-bottom: 1rem;
  }
}

.page-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.45rem 0.85rem;
  margin-bottom: 0.15rem;
  border-radius: 0.35rem;
  cursor: pointer;
  @include transition;

  span {
    font-size: $smallFontSize;
    line-height: auto;
    color: $paragraphColor;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .unicon /deep/ {
    svg {
      fill: $secondaryColor;
      height: auto;
      width: 1rem;
      margin-right: 0.75rem;
    }
  }

  &:hover {
    background-color: darken($lightWhiteColor, 5%);
    text-decoration: none;
  }
  &.router-link-exact-active {
    background-color: darken($lightWhiteColor, 5%);
    span {
      font-weight: $mediumFontWeight;
    }
  }
}

.highlight-matched-route {
  .router-link-active {
    background-color: darken($lightWhiteColor, 5%);
    span {
      font-weight: $mediumFontWeight;
    }
  }
}
</style>