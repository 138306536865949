<template>
  <div
    class="modal modal-large no-overflow show"
    :class="{ 'modal-success': showOptions.state == 'success' }"
  >
    <!--START: Header-->
    <ModalHeader :title="title" @closeModal="closeModal"></ModalHeader>
    <!--END: Header-->

    <!--START: Modal Body-->
    <div
      v-if="showOptions.state == 'form'"
      class="modal-body modal-header-body"
    >
      <div class="internal-modal-body">
        <!--START: Title-->
        <div
          class="title-wrapper"
          :class="{ 'show-shadow': showOptions.headerShadow }"
        >
          <div class="title-info">
            <h3 class="title">Add Source</h3>
            <p>
              Add a source to your knowledge base for the AI to create answers
            </p>
          </div>
          <div class="actions-wrapper">
            <button class="btn btn-small btn-primary" @click="addSource">
              Continue
            </button>
          </div>
        </div>
        <!--END: Title-->

        <div ref="scrollBody" class="scroll-body" @scroll="bodyScroll">
          <SettingsForm
            :fields="sourceFields.fields"
            :content="sourceFields.content"
            additionalClass="two-column-form"
            @fieldChanged="fieldChanged"
          ></SettingsForm>

          <ShopifyOptions
            v-if="showOptions.shopifyOptions"
            @optionsChanged="optionsChanged"
          ></ShopifyOptions>

          <ExternalWebsiteOptions
            v-if="showOptions.externalWebsiteOptions"
            @optionsChanged="optionsChanged"
          ></ExternalWebsiteOptions>
        </div>
      </div>
    </div>
    <!--END: Modal Body-->

    <!--START: Status Message-->
    <SuccessForm
      v-else-if="showOptions.state == 'success'"
      :successForm="successForm"
      @secondaryEvent="closeModal"
    ></SuccessForm>
    <!--END: Status Message-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
      @closeModal="closeModal"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader" class="bottom"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
              
<script>
// Import libraries
import _ from "lodash";

// Importing components
import ModalHeader from "@/components/modals/ModalHeader";
import SettingsForm from "@/components/form/SettingsForm";
import ShopifyOptions from "./sources/Shopify/Options";
import ExternalWebsiteOptions from "./sources/ExternalWebsite/Options";
import LineLoader from "@/components/loaders/LineLoader";
import NotificationMessage from "@/components/modals/NotificationMessage";
import SuccessForm from "@/components/modals/SuccessForm";

// Importing Services
import { DocumentService } from "@/services";

export default {
  name: "AddSource",
  props: {},
  watch: {},
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  data() {
    return {
      title: "Add Source",
      showOptions: {
        state: "form",
        headerShadow: false,
        shopifyProducts: false,
        zendeskHelpCenter: false,
        shopifyOptions: false,
        externalWebsiteOptions: false,
        lineLoader: false,
      },
      sourceFields: {
        content: {
          title: "Source Details",
          description: "Add details of the source type",
        },
        fields: {
          sourceType: {
            type: "dropdown",
            placeholder: "Select type",
            title: "Source type*",
            required: true,
            hasError: false,
            fields: [
              { name: "Shopify Products", value: "shopify-products" },
              { name: "Zendesk Help Center", value: "zendesk-hc" },
              { name: "External Website", value: "external-website" },
            ],
            value: [],
          },
          name: {
            type: "text",
            placeholder: "Name for the source",
            required: true,
            hasError: false,
            title: "Name*",
            value: "",
          },
          url: {
            type: "text",
            placeholder: "Your source URL",
            required: true,
            hasError: false,
            disabled: false,
            title: "URL*",
            value: "",
          },
          updateFrequency: {
            type: "dropdown",
            placeholder: "Select frequency",
            title: "Update frequency*",
            required: true,
            hasError: false,
            fields: [
              { name: "None", value: "none" },
              { name: "Weekly", value: "weekly" },
              { name: "Monthly", value: "monthly" },
            ],
            value: [],
          },
        },
      },
      integrationOptions: {
        zendeskMacro: { name: "Zendesk Macro", value: "zendesk-macro" },
        zendeskHelpCenter: {
          name: "Zendesk Help Center",
          value: "zendesk-hc-api",
        },
        zendeskTickets: {
          name: "Zendesk Tickets",
          value: "zendesk-tickets",
        },
      },
      shopifyProductsFields: {
        currency: {
          type: "text",
          placeholder: "USD, INR, GBP...",
          required: true,
          hasError: false,
          title: "Shop currency*",
          value: "",
        },
      },
      zendeskTicketFields: {
        customQuery: {
          type: "text",
          placeholder: "Add a custom query",
          required: false,
          hasError: false,
          title: "Use custom search: query=",
          value: "",
        },
      },
      additionalOptions: {},
      successForm: {
        show: true,
        status: "success",
        title: "Source Successfully Added",
        message:
          "Your source has been successfully added! Your articles will be added to the knowledge base",
        secondaryBtn: { title: "Close" },
      },
      status: {
        show: false,
        status: "success",
        title: "Source added",
      },
    };
  },
  components: {
    ModalHeader,
    SettingsForm,
    ShopifyOptions,
    ExternalWebsiteOptions,
    LineLoader,
    NotificationMessage,
    SuccessForm,
  },
  created() {
    this.initSources();
  },
  methods: {
    // Initialise the integration based sources
    initSources() {
      const { integrations, sources } = this.company;
      const options = this.sourceFields.fields.sourceType.fields;

      if (integrations.zendesk.isActive) {
        let hasSource = sources.find((s) => s.sourceType == "zendesk-macro");
        if (!hasSource) {
          options.push(this.integrationOptions.zendeskMacro);
        }

        hasSource = sources.find((s) => s.sourceType == "zendesk-hc-api");
        if (!hasSource) {
          this.integrationOptions.zendeskHelpCenter.name = `${this.integrationOptions.zendeskHelpCenter.name} for ${integrations.zendesk.credentials.domainName}`;
          options.push(this.integrationOptions.zendeskHelpCenter);
        }

        hasSource = sources.find((s) => s.sourceType == "zendesk-tickets");
        if (!hasSource || this.company.subscription.increaseTicketCount) {
          this.integrationOptions.zendeskTickets.name = `${this.integrationOptions.zendeskTickets.name} for ${integrations.zendesk.credentials.domainName}`;
          options.push(this.integrationOptions.zendeskTickets);
        }
      }
    },

    // Add a new source
    async addSource() {
      if (!this.showOptions.lineLoader) {
        this.showOptions.lineLoader = false;
        const data = { ...this.sourceFields.fields };
        var isFormValid = this.validateForm(data);

        if (isFormValid) {
          this.showOptions.lineLoader = true;
          const payload = this.constructPayload(data);

          const response = await DocumentService.AddSource(payload);
          if (!response.hasError) {
            this.showOptions.state = "success";
            this.$store.commit("updateCompany", response.data);
          } else this.showErrorMessage(this.status, response.message);
        }

        this.showOptions.lineLoader = false;
      }
    },

    constructPayload(data) {
      let options = {};
      let payloadData = this.parseData(data);

      const sourceType = this.sourceFields.fields.sourceType.value[1];
      let omitFields = [];
      switch (sourceType) {
        case "shopify-products":
          omitFields = Object.keys(this.shopifyProductsFields);
          options = {
            ..._.pick(payloadData, omitFields),
            selectors: this.additionalOptions,
          };
          break;
        case "external-website":
          options = {
            selectors: this.additionalOptions,
          };
          break;
      }

      const payload = { ..._.omit(payloadData, omitFields), options };
      return payload;
    },

    optionsChanged(options) {
      this.additionalOptions = options;
    },

    fieldChanged() {
      const sourceFields = this.sourceFields.fields;
      const sourceType = sourceFields.sourceType.value[1];
      this.setDefaultFields();

      switch (sourceType) {
        case "shopify-products":
          this.addAppFields("shopifyProductsFields");
          this.showOptions.shopifyOptions = true;
          break;
        case "external-website":
          this.showOptions.externalWebsiteOptions = true;
          break;
        case "zendesk-macro":
        case "zendesk-hc-api":
        case "zendesk-tickets":
          if (sourceType === "zendesk-tickets")
            this.addAppFields("zendeskTicketFields");

          this.sourceFields.fields.url.disabled = true;
          this.sourceFields.fields.url.value =
            this.company.integrations?.zendesk?.credentials?.domainName;
          this.sourceFields.fields.updateFrequency.disabled = true;
          this.sourceFields.fields.updateFrequency.value = ["auto", "auto"];
      }
    },

    setDefaultFields() {
      const sourceFields = this.sourceFields.fields;
      this.sourceFields.fields = _.pick(sourceFields, [
        "sourceType",
        "name",
        "url",
        "updateFrequency",
      ]);

      this.sourceFields.fields.url.disabled = false;
      this.sourceFields.fields.updateFrequency.disabled = false;
      this.showOptions.shopifyOptions = false;
      this.showOptions.externalWebsiteOptions = false;
    },

    addAppFields(appFields) {
      this.sourceFields.fields = {
        ...this.sourceFields.fields,
        ...this[appFields],
      };
    },

    bodyScroll() {
      const scrollTop = this.$refs.scrollBody.scrollTop;

      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    closeModal() {
      this.showOptions.lineLoader = false;
      this.$emit("closeModal");
    },
  },
};
</script>
              
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
</style>