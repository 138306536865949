<template>
  <!--START: Ticket-->
  <div class="ticket-wrapper">
    <!--START: Ticket Header-->
    <div
      class="ticket-header"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <div class="subject-wrapper">
        <h3 v-if="ticket.subject" class="subject">{{ ticket.subject }}</h3>
        <h3 v-else class="subject no-subject">No subject</h3>
        <span class="answer-count"
          >{{ tasks.length }}
          {{ tasks.length > 1 ? "tasks" : "task" }} generated</span
        >
      </div>
      <div class="details-wrapper">
        <div class="ticket-information">
          <span class="customer-name"
            >From <b>{{ getCustomerName(ticket).name }}</b></span
          >
          <a
            class="ticket-id"
            :href="`https://${zendeskDomain}.zendesk.com/agent/tickets/${ticket.ticketID}`"
            target="_blank"
            ><unicon name="link"></unicon>{{ ticket.ticketID }}</a
          >
          <span class="ticket-channel">{{
            zendeskTicket.via?.channel ? zendeskTicket.via?.channel : "-"
          }}</span>
        </div>

        <span class="update-time"
          >Last updated: <b>{{ getLastMessageTime(ticket.updatedAt) }}</b></span
        >
      </div>
    </div>
    <!--START: END Header-->

    <!--START: Ticket Body-->
    <div class="ticket-body">
      <!--START: Answers-->
      <div ref="ticketWindow" class="answers-list" @scroll="ticketScroll">
        <div v-for="(t, index) in tasks" :key="t._id" class="answer-item">
          <span class="answer-index">{{ tasks.length - index }}</span>

          <!--START: Details-->
          <div class="answer-details">
            <span class="timestamp">
              {{ moment(t.analysis.createdAt).format("Do MMMM, hh:MM A") }}
            </span>
          </div>
          <!--END: Details-->

          <!--START: Task-->
          <div class="ticket-analysis card">
            <!--START: Customer Question-->
            <div v-if="!lodash.isEmpty(zendeskTicket)" class="ticket-comments">
              <div class="ticket-comments-title">
                <h3>Message that triggered the task</h3>
                <span>{{
                  moment(
                    getLastZendeskComment(t.analysis.lastCommentID).created_at
                  ).format("Do MMMM, hh:MM A")
                }}</span>
              </div>
              <div
                class="comment"
                v-html="
                  getLastZendeskComment(t.analysis.lastCommentID).html_body
                "
              ></div>
            </div>
            <!--END: Customer Question-->

            <!--START: Answer-->
            <div
              v-if="!lodash.isEmpty(t.response)"
              class="ai-answer"
              :class="{ error: t.response.answerConfidence?.score < 4 }"
            >
              <div class="ai-avatar">
                <unicon name="bolt"></unicon>
                <div class="avatar-details">
                  <h3>Macha AI</h3>
                  <span>Answer generated by AI</span>
                </div>

                <div class="answer-details">
                  <div
                    class="action-step green"
                    :class="{ error: t.response.answerConfidence?.score < 4 }"
                  >
                    <p>
                      <span class="topic">{{
                        t.response.answerConfidence?.score
                      }}</span>
                      - Confidence score
                    </p>
                  </div>
                </div>
              </div>
              <div class="answer" v-html="t.response.answer"></div>
            </div>
            <!--END: Answer-->

            <!--START: Ticket Analysis-->
            <div class="analysis-wrapper">
              <div class="accordion">
                <h3>Ticket analysis</h3>
                <span>
                  {{ moment(t.analysis.createdAt).format("Do MMMM, hh:MM A") }}
                </span>
              </div>
              <div
                class="action-step green"
                :class="{ error: t.analysis.topic === 'Not Applicable' }"
              >
                <unicon
                  :name="
                    t.analysis.topic === 'Not Applicable' ? 'times' : 'check'
                  "
                ></unicon>
                <p>
                  Categorised as
                  <span class="topic">{{ t.analysis.topic }}</span>
                </p>
              </div>
              <div
                class="action-step green"
                :class="{ error: t.analysis.ignoreTicket }"
              >
                <unicon
                  :name="!t.analysis.ignoreTicket ? 'check' : 'times'"
                ></unicon>
                <p>
                  <span class="filter">{{
                    t.analysis.ignoreTicket ? "Ignore Ticket" : "Process Ticket"
                  }}</span>
                  {{ t.analysis.ignoreTicketExplanation }}
                </p>
              </div>
              <div class="action-step short-message">
                <div>
                  <label>Summarized interpretation by AI</label>
                  <p class="shortened-message">
                    {{ t.analysis.shortenedCustomerMessage }}
                  </p>
                </div>
              </div>
            </div>
            <!--END: Ticket Analysis-->

            <!--START: Answer Analysis-->
            <div
              v-if="!lodash.isEmpty(t.response)"
              class="answer-analysis-wrapper"
            >
              <div class="accordion">
                <h3>Answer Analysis</h3>
                <span>
                  {{ moment(t.response.createdAt).format("Do MMMM, hh:MM A") }}
                </span>
              </div>
              <div
                class="action-step green"
                :class="{ error: t.response.answerConfidence?.score < 4 }"
              >
                <unicon
                  :name="
                    t.response.answerConfidence?.score >= 4 ? 'check' : 'times'
                  "
                ></unicon>
                <p>
                  <span class="topic">{{
                    t.response.answerConfidence?.score
                  }}</span>
                  - Confidence score in answer's relevance
                </p>
              </div>
              <div class="action-step short-message">
                <div>
                  <label>Confidence score explanation</label>
                  <p class="shortened-message">
                    {{ t.response.answerConfidence?.explanation }}
                  </p>
                </div>
              </div>
            </div>
            <!--END: Answer Analysis-->
          </div>
          <!--END: Task-->
        </div>
      </div>
      <!--END: Answers-->
    </div>
    <!--END: Ticket Body-->
  </div>
  <!--END: Ticket-->
</template>
  
<script>
// Importing Services
import _ from "lodash";
import "external-svg-loader";
import { getLastMessageTime } from "@/utils/ticket.utils";

export default {
  name: "SelectedTicket",
  data() {
    return {
      lodash: _,
      showOptions: {
        headerShadow: false,
      },
      selectedInstructions: [],
    };
  },
  props: {
    ticket: { type: Object, required: true },
    zendeskTicket: { type: Object, required: true },
  },
  watch: {},
  components: {},
  computed: {
    tasks() {
      const tasks = [];
      this.ticket.aiAnalysis.forEach((analysis) => {
        const taskID = analysis.taskID;
        const response = this.ticket.responses.find((r) => r.taskID === taskID);
        const action = this.ticket.aiAgentActions.find(
          (a) => a.taskID === taskID
        );

        tasks.push({ taskID, analysis, response, action });
      });

      return tasks.reverse();
    },

    company() {
      return this.$store.getters.company;
    },

    zendeskDomain() {
      return this.company.integrations?.zendesk?.credentials?.domainName;
    },
  },
  async mounted() {},

  methods: {
    // Get the last message time
    getLastMessageTime,

    // Get the customer name or email
    getCustomerName(ticket) {
      let name = "Customer";
      let email = null;
      if (ticket.customer && ticket.customer.name) {
        name = ticket.customer.name;
        email = ticket.customer.email;
      } else if (ticket.customer && ticket.customer.email)
        name = ticket.customer.email;

      return { name, email };
    },

    // Toggle the views for the isntructions
    toggleView(index) {
      const selectedIndex = this.selectedInstructions.indexOf(index);
      if (selectedIndex == -1) this.selectedInstructions.push(index);
      else this.selectedInstructions.splice(index, 1);
    },

    // Get the last Zendesk comment used to run the task
    getLastZendeskComment(lastCommentID) {
      if (lastCommentID && !_.isEmpty(this.zendeskTicket)) {
        const lastComment = this.zendeskTicket.comments.find(
          (c) => c.id.toString() === lastCommentID
        );
        return lastComment;
      }

      return {};
    },

    // Event on ticket scroll
    ticketScroll() {
      const scrollTop = this.$refs.ticketWindow.scrollTop;

      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },
  },
};
</script>
      
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.ticket-wrapper {
  width: 100%;

  .ticket-body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background: #fbfbfd;
  }
}

.ticket-header {
  padding: 1rem 2rem;
  border-bottom: 1px solid #edeefa;

  .subject-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 1.5rem;

    .subject {
      flex: 1;
      font-size: $mediumFontSize;
      margin-bottom: 0;

      &.no-subject {
        opacity: 0.5;
      }
    }

    .answer-count {
      display: block;
      border-radius: 0.25rem;
      color: $whiteColor;
      background-color: $purpleColor;
      font-size: 12px;
      line-height: 1;
      text-align: center;
      padding: 0.25rem 0.5rem;
    }
  }

  .details-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 1.5rem;
    margin-top: 0.25rem;

    .ticket-information {
      flex: 1;
    }

    .ticket-link {
      color: $greyColor;
      font-size: $smallerFontSize;

      .unicon /deep/ svg {
        fill: $greyColor;
        width: 12px;
        height: auto;
        margin: 0 5px 0 0;
        transform: translateY(2px);
      }
    }

    .customer-name {
      font-size: $smallerFontSize;
      color: $greyColor;

      b {
        font-weight: $mediumFontWeight;
      }
    }

    .update-time {
      flex: 1;
      text-align: right;
      font-size: $smallestFontSize;
      color: $greyColor;

      b {
        font-weight: $mediumFontWeight;
      }
    }
  }
}

.answers-list {
  padding: 2rem 3rem;
  height: calc(100vh - 5rem - 4.15rem - 4rem);
  overflow-y: scroll;
}

.answer-item {
  position: relative;
  margin-left: 3.5rem;
  margin-bottom: 4rem;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: calc(-3rem + 0.25rem + 6px);
    top: 0;
    width: 1px;
    height: calc(100% + 3rem);
    border-left: 1px dashed darken($headerBorderColor, 3.5%);
  }

  .answer-index {
    position: absolute;
    font-size: 10px;
    top: 0;
    left: -3.25rem;
    width: 1rem;
    text-align: center;
    color: darken($whiteColor, 45%);
    background: darken($whiteColor, 7.5%);
    border: 1px solid darken($whiteColor, 10.5%);
    padding: 0.25rem;
    border-radius: 0.5rem;
  }

  .answer-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    .agent-name {
      flex: 1;
      color: $greyColor;
      font-size: $smallerFontSize;
      font-weight: $mediumFontWeight;
      margin-right: 0.5rem;
    }

    .answer-type {
      font-size: 0.65rem;
      font-weight: $mediumFontWeight;
      background-color: $greenMessageColor;
      color: $greenMessageTextColor;
      border: 1px solid $greenMessageBorderColor;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
    }

    .timestamp {
      color: $greyColor;
      font-size: $smallestFontSize;
      opacity: $lightOpacity;
    }
  }

  .answer-block {
    position: relative;
    margin-top: 1rem;

    &.added-to-editor {
      border: 2px solid $purpleColor;
    }

    .editor-added {
      background-color: $purpleColor;
      position: absolute;
      color: $whiteColor;
      font-size: $smallestFontSize;
      padding: 0.25rem 1rem;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      top: 100%;
      right: 1rem;
    }

    &:hover {
      .answer {
        color: $blackColor;
      }
    }

    .answer {
      color: $greyColor;
      font-size: $smallerFontSize;
      line-height: 1.4;

      /deep/ {
        ol,
        ul {
          list-style: inherit;
        }
      }
    }
  }
}

.instruction-wrapper {
  margin-top: 1.5rem;
  background: darken($whiteColor, 5%);
  border: 1px solid darken($whiteColor, 7.5%);
  border-radius: 0.75rem;
  padding: 0.35rem 0.5rem;
  transition: all 0.3s;
  cursor: pointer;

  .btn-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span {
      font-size: $smallestFontSize;
      color: lighten($darkBlackColor, 30%);
    }

    .unicon /deep/ svg {
      height: auto;
      width: 1.25rem;
      fill: lighten($darkBlackColor, 30%);
      transform: translateY(2px);
      margin-left: 0.5rem;
      opacity: $lightOpacity;
    }
  }

  .instruction-block {
    display: none;
    flex-direction: row;
    align-items: flex-start;
    gap: 2.5rem;
    padding: 1rem;

    .left-col {
      width: 55%;
    }

    .right-col {
      width: 45%;
    }

    .instruction-item {
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }

      label {
        display: block;
        color: darken($whiteColor, 10%);
        font-size: $smallestFontSize;
        font-weight: 500;
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid lighten($darkBlackColor, 15%);
      }
      p,
      span {
        color: lighten($darkBlackColor, 40%);
        font-size: $smallestFontSize;
        white-space: pre-wrap;
      }

      p {
        color: darken($whiteColor, 20%);
        margin-left: 0.5rem;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .kb-instruction {
        position: relative;
        margin-left: 1rem;
        margin-bottom: 0.5rem;

        &::before {
          content: "";
          position: absolute;
          display: block;
          height: 5px;
          width: 5px;
          border-radius: 50%;
          background-color: #22ce61;
          top: 5px;
          left: -0.75rem;
        }
      }
    }
  }

  &:hover,
  &.selected {
    background: lighten($darkBlackColor, 5%);
    border: 1px solid #595959;

    .btn-wrapper {
      span {
        color: darken($whiteColor, 30%);
      }

      .unicon /deep/ svg {
        fill: darken($whiteColor, 30%);
      }
    }
  }

  &.selected {
    .btn-wrapper {
      display: none;
    }

    .instruction-block {
      display: flex;
    }
  }
}

.reference-ticket {
  margin-top: 2.5rem;
  background: darken($whiteColor, 5%);
  border: 1px solid darken($whiteColor, 7.5%);
  border-radius: 0.75rem;
  padding: 0.35rem 1rem;

  label {
    font-size: $smallestFontSize;
    color: lighten($darkBlackColor, 30%);
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $smallerFontSize;
    font-weight: $mediumFontWeight;
    color: $blackColor;
    margin-top: 0.25rem;

    :deep(svg) {
      height: auto;
      width: 0.75rem;
      opacity: 0.5;
    }

    em {
      margin: 0 0.5rem;
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &:hover {
      color: $purpleColor;
    }
  }
}

.analysis-wrapper {
  .analysis-item {
    margin-bottom: 1rem;

    &.analysis-row {
      display: flex;
      flex-direction: row;
      align-items: center;

      label {
        margin-bottom: 0;
        width: 40%;
      }
    }
  }

  label {
    display: block;
    font-size: $smallerFontSize;
    color: $greyColor;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .summary {
    color: $greyColor;
    font-size: $smallestFontSize;
    line-height: 1.6;
    margin-bottom: 2rem;

    /deep/ span {
      background: #ffdc6f;
      padding: 0 3px;
      border-radius: 5px;
      color: #1d1600;
    }
  }

  .actions,
  .issues,
  .category {
    color: $greyColor;
    font-size: $smallerFontSize;
  }

  .category {
    text-transform: capitalize;
  }

  .actions,
  .issues {
    margin-top: 2rem;

    span {
      position: relative;
      display: block;
      margin-left: 1.5rem;

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: -1rem;
        top: 7px;
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background: $purpleColor;
      }
    }
  }

  .sentiment {
    font-size: $smallestFontSize;
    padding: 3px 8px;
    border-radius: 0.5rem;
    font-weight: 500;
    text-transform: capitalize;

    &.negative,
    &.sad,
    &.angry,
    &.annoyed,
    &.disappointed,
    &.urgent {
      color: #cb2828;
      background-color: #ffdede;
      border: 1px solid #ff9393;
    }

    &.neutral,
    &.non-urgent {
      color: #8f6901;
      background-color: #fff8e4;
      border: 1px solid #ffd869;
    }

    &.happy,
    &.excited,
    &.positive {
      color: #37b51c;
      background-color: #eeffeb;
      border: 1px solid #a5d09c;
    }
  }
}

.zero-state {
  margin-top: 3rem;

  /deep/ {
    h3 {
      font-size: $normalFontSize;
    }
    p {
      font-size: $smallerFontSize;
    }
  }
}

.failed-generation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: -0.5rem 0;

  span {
    margin-left: 0.5rem;
    font-size: $smallFontSize;
    color: $greyColor;
  }
}

.show-shadow {
  position: relative;
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
  z-index: 1;
}

.action-step {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  width: fit-content;

  /deep/ .unicon {
    margin-right: 0.5rem;
    transform: translateY(2.5px);

    svg {
      background-color: $greenColor;
      fill: darken($greenColor, 40%);
      border-radius: 50%;
      width: 0.95rem;
      height: auto;
    }
  }

  &.green {
    background: lighten($greenColor, 35%);
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid darken($greenColor, 5%);

    p {
      color: darken($greenColor, 40%);
      font-size: $smallFontSize;
    }
  }

  &.error {
    background: lighten($redColor, 35%);
    border: 1px solid darken($redColor, 5%);

    p {
      color: darken($redColor, 40%);
    }

    /deep/ .unicon svg {
      background-color: darken($redColor, 40%);
      fill: lighten($redColor, 40%);
    }
  }

  .topic,
  .filter {
    font-weight: $boldFontWeight;
  }

  .filter {
    display: block;
  }

  &.short-message {
    background: $whiteColor;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid darken($whiteColor, 15%);
    margin-top: 1rem;

    label {
      display: block;
      color: $darkBlackColor;
      font-size: $smallerFontSize;
      font-weight: $mediumFontWeight;
      margin-bottom: 0.5rem;
    }

    p {
      color: $darkBlackColor;
      font-size: $smallFontSize;
      font-weight: $normalFontWeight;
      opacity: 0.7;
    }
  }
}

.accordion {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;

  h3 {
    font-size: $smallFontSize;
    font-weight: $mediumFontWeight;
    flex: 1;
    margin-bottom: 0;
  }

  span {
    font-size: $smallerFontSize;
    color: $greyColor;
    opacity: $lightOpacity;
  }
}

.answer-analysis-wrapper {
  margin-top: 2rem;
}

.ai-answer {
  background: $darkBlackColor;
  border-radius: 1rem;
  font-size: $smallFontSize;
  line-height: 1.4;
  padding: 1.5rem 2rem;
  color: lighten($darkBlackColor, 75%);
  border: 4px solid darken($greenColor, 5%);
  margin-bottom: 2rem;

  /deep/ a {
    color: $blueColor;
  }

  &.error {
    border: 4px solid darken($redColor, 5%);
  }

  .ai-avatar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid lighten($darkBlackColor, 5%);

    .avatar-details {
      flex: 1;
    }

    .action-step {
      padding: 0.25rem 0.75rem;

      span,
      p {
        font-size: $smallestFontSize;
      }
    }

    .unicon /deep/ {
      margin-right: 0.75rem;

      svg {
        height: auto;
        width: 1.25rem;
        background: $purpleColor;
        border-radius: 0.5rem;
        padding: 0.25rem;
        fill: lighten($purpleColor, 55%);
      }
    }

    h3 {
      font-weight: $mediumFontWeight;
      font-size: $smallerFontSize;
      color: $whiteColor;
      margin-bottom: 0;
    }

    span {
      color: darken($whiteColor, 45%);
      font-size: $smallerFontSize;
    }
  }
}

.ticket-comments-title {
  display: flex;
  flex-direction: row;
  align-items: center;

  h3 {
    font-size: $normalFontSize;
    font-weight: $mediumFontWeight;
    margin-bottom: 0;
    flex: 1;
  }
  span {
    font-size: $smallestFontSize;
    color: $greyColor;
  }
}

.comment {
  font-size: $smallFontSize;
  color: #50506e;
  line-height: 1.4;
  padding: 1.5rem;
  border: 1px solid $purpleColor;
  background: #f6f6ff;
  border-radius: 1rem;
  margin: 0.5rem 0 2rem;
}

.ticket-channel {
  font-size: $smallestFontSize;
  margin-left: 0.5rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  background-color: darken($whiteColor, 15%);
  color: darken($whiteColor, 65%);
}

.ticket-id {
  margin-left: 1rem;
  font-size: $smallestFontSize;
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  background-color: $darkBlackColor;
  color: $whiteColor;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: $blueColor;
  }

  /deep/ .unicon svg {
    height: auto;
    width: 0.65rem;
    fill: $whiteColor;
    margin-right: 0.15rem;
    margin-left: -0.15rem;
  }
}
</style>