<template>
  <div class="inbox-wrapper">
    <!--START: All Mesages Title-->
    <div
      class="inbox-options-wrapper"
      :class="{ 'show-shadow': showOptions.inboxHeaderShadow }"
    >
      <h1>
        Tickets
        <span v-if="totalCount > 0" class="unread-count">{{ totalCount }}</span>
      </h1>
    </div>
    <!--END: All Mesages Title-->

    <!--START: All Mesages-->
    <div
      v-if="ticketsLoaded"
      class="inbox-messages-wrapper"
      ref="inbox"
      @scroll="inboxScroll"
    >
      <div
        class="ticket-item"
        v-for="(ticket, index) in tickets"
        :key="ticket._id"
        :class="{
          selected: ticketIndex == index,
        }"
        @click="getTicket(ticket, index)"
      >
        <span v-if="ticket.subject" class="name">{{ ticket.subject }}</span>
        <span v-else class="no-subject">No subject</span>
        <div class="last-message-wrapper">
          <span class="ticketID">#{{ ticket.ticketID }}</span
          ><span class="last-message">{{ getCustomerName(ticket).name }}</span>
          <span class="message-count">{{
            ticketType === "ai-agent"
              ? ticket.aiAnalysis.length
              : ticket.answers.length
          }}</span>
        </div>
        <span class="timestamp">{{
          getLastMessageTime(ticket.updatedAt)
        }}</span>
      </div>
      <span v-if="tickets.length == 0" class="empty-inbox"
        >Your inbox is empty</span
      >
    </div>
    <!--END: All Mesages-->

    <!--START: Page Loader-->
    <div v-else class="pre-loader-wrapper">
      <PreLoader :show="true" v-for="index in 5" :key="index"></PreLoader>
    </div>
    <!--START: Page Loader-->
  </div>
</template>
        
<script>
// Import functions
import { getLastMessageTime } from "@/utils/ticket.utils";

// Importing components
import PreLoader from "@/components/loaders/PreLoader";

export default {
  name: "AllTickets",
  data() {
    return {
      showOptions: {
        inboxHeaderShadow: false,
      },
      status: {
        show: false,
        status: null,
        title: null,
        message: null,
      },
    };
  },
  props: {
    tickets: { type: Array, required: true },
    ticketIndex: { type: Number, required: true },
    totalCount: { type: Number, required: true },
    ticketsLoaded: { type: Boolean, required: true, default: true },
    ticketType: { type: String, required: false, default: "ai-assistant" },
  },
  watch: {},
  components: {
    PreLoader,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  async created() {},
  methods: {
    // Get the last message time
    getLastMessageTime,

    // Function to show the selected ticket
    getTicket(ticket, index) {
      this.$emit("ticketSelected", ticket, index);
    },

    // Event when field changed
    fieldChanged() {
      let getUnreadChats;
      if (this.fields.view.value == "unread") getUnreadChats = true;

      this.$emit("filterChanged", getUnreadChats);
    },

    // Event on inbox scroll
    inboxScroll() {
      const scrollTop = this.$refs.inbox.scrollTop;
      const scrollHeight = this.$refs.inbox.scrollHeight;
      const clientHeight = this.$refs.inbox.clientHeight;
      const bufferHeight = scrollHeight - clientHeight;

      if (scrollTop > 0) this.showOptions.inboxHeaderShadow = true;
      else this.showOptions.inboxHeaderShadow = false;

      // Check for inifinite scroll
      if (bufferHeight - scrollTop < 30) this.$emit("getAllTickets");
    },

    // Get the customer name or email
    getCustomerName(chat) {
      let name = "Customer";
      let email = null;
      if (chat.customer && chat.customer.name) {
        name = chat.customer.name;
        email = chat.customer.email;
      } else if (chat.customer && chat.customer.email)
        name = chat.customer.email;

      return { name, email };
    },
  },
};
</script>
        
<style scoped lang="scss">
.inbox-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .inbox-messages-wrapper {
    flex: 1;
    overflow-y: scroll;
  }
}

.inbox-options-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem 2.25rem;
  position: relative;
  z-index: 2;

  h1 {
    flex: 1;
    font-size: $normalFontSize;
    margin-bottom: 0;

    .unread-count {
      display: inline-block;
      color: $paragraphColor;
      font-size: $smallestFontSize;
      font-weight: $normalFontWeight;
      margin-left: 0.5rem;
      opacity: $lightOpacity;
      transform: translateY(-0.5px);
    }
  }

  .inbox-options {
    transform: translateX(0.75rem);

    /deep/ {
      .field-main-item,
      .field-radio-wrapper {
        margin-bottom: 0;
      }
    }
  }
}

.ticket-item {
  position: relative;
  padding: 0.75rem 0.75rem 0.75rem 2rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid transparent;
  cursor: pointer;

  &::after {
    position: absolute;
    display: block;
    content: "";
    border-bottom: 1px solid lighten($greyBorderColor, 5%);
    left: 0.75rem;
    top: -0.25rem;
    width: calc(100% - 1.5rem);
  }

  &:first-child {
    margin-top: 0;

    &::after {
      display: none;
    }
  }

  .no-subject,
  .name {
    display: block;
    font-size: $smallFontSize;
    opacity: 0.75;
    font-weight: $mediumFontWeight;
    color: $darkBlackColor;
    margin-bottom: 0.15rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 3.5rem);
    flex: 1;
  }

  .no-subject {
    opacity: 0.5;
  }

  .chat-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.75rem 0 0.25rem;
  }

  .category {
    display: block;
    font-size: 10px;
    font-weight: $mediumFontWeight;
    text-transform: capitalize;
    padding: 0.25rem 0.65rem;
    border-radius: 0.35rem;
    color: lighten($darkBlackColor, 25%);
    background-color: darken($whiteColor, 7.5%);
  }

  .status {
    display: block;
    height: 0.75rem;
    margin-right: 0.5rem;
    width: 0.25rem;
    border-radius: 0.5rem;
    color: $whiteColor;

    &.green {
      background-color: $emeraldColor;
    }

    &.yellow {
      background-color: $yellowColor;
    }

    &.red {
      background-color: $redColor;
    }
  }

  .ticket-details {
    flex: 1;
    text-align: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    transform: translateX(0.5rem);
  }

  .ticketID {
    font-size: 10px;
    color: $greyColor;
    opacity: 0.5;
    margin-right: 0.5rem;
  }

  .flagged-chat {
    /deep/ svg {
      fill: $redColor;
      width: 1rem;
      height: auto;
      margin: 0.15rem 0 0 0.25rem;
    }
  }

  .last-message-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .last-message {
      flex: 1;
      display: block;
      color: $darkBlackColor;
      font-size: 0.7rem;
      opacity: 0.55;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .message-count {
      display: block;
      border-radius: 0.5rem;
      color: #969696;
      background-color: #f5f5f5;
      border: 1px solid #ececec;
      font-size: 10px;
      min-width: 12px;
      height: 10px;
      line-height: 1;
      text-align: center;
      padding: 4px;
      margin-left: 0.5rem;
    }
  }

  .timestamp {
    position: absolute;
    color: $greyColor;
    font-size: 10px;
    opacity: 0.55;
    top: 0.8rem;
    right: 0.5rem;
  }

  &.unread {
    .name,
    .last-message {
      color: $blackColor;
      font-weight: $boldFontWeight;
      opacity: $fullOpacity;
    }

    .name {
      color: $darkBlackColor;
    }

    .last-message {
      font-weight: $mediumFontWeight;
    }

    .timestamp {
      color: $purpleColor;
      font-weight: $mediumFontWeight;
      opacity: $fullOpacity;
    }
  }

  &:hover {
    background: $secondaryColor;
    border-color: lighten($secondaryColor, 5%);

    background-color: darken($lightWhiteColor, 0%);
    border-color: darken($lightWhiteColor, 2.5%);

    .category {
      background-color: darken($lightWhiteColor, 5.5%);
    }

    & + .ticket-item:after,
    &::after {
      display: none;
    }
  }

  &.selected {
    background-color: darken($lightWhiteColor, 5%);
    border-color: darken($lightWhiteColor, 5%);
    & + .ticket-item:after,
    &::after {
      display: none;
    }

    .name {
      opacity: $fullOpacity;
    }

    .last-message,
    .timestamp {
      opacity: $mediumOpacity;
    }

    .message-count {
      background-color: $purpleColor;
      color: $whiteColor;
    }

    .category {
      color: $whiteColor;
      background-color: $secondaryColor;
    }
  }
}

.show-shadow {
  box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
}

.pre-loader-wrapper {
  margin: 0 2rem;

  /deep/ {
    .buffer-line {
      width: auto;
    }
    .buffer-category {
      display: none;
    }
  }
}

.empty-inbox {
  display: block;
  font-size: $smallFontSize;
  color: $paragraphColor;
  margin: 0 1rem;
  padding: 2rem 2rem 0;
  border-top: 1px solid $greyBorderColor;
  text-align: center;
}
</style>