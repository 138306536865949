require("dotenv").config();
import axios from "axios";

//Importing Variable mixins
const apiURL = `${process.env.VUE_APP_API_URL}/company`;

class CompanyService {
  // Update the company
  static UpdateCompany(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/update`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Add integration
  static AddIntegration(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/integrations/add`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Activate Product
  static ActivateProduct(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/product/activate`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Remove integration
  static RemoveIntegration(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/integrations/remove`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Update integration
  static UpdateIntegration(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/integrations/update`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Disable onboarding
  static DisableOnboarding() {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .get(`${apiURL}/onboarding/disable`, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Get all documents from sources
  static ReloadShopifyWebhooks() {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .get(`${apiURL}/shopify/reload-webhooks`, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }

  // Save AI agent settings
  static SaveAiAgentSettings(data) {
    return new Promise((resolve, reject) => {
      resolve(
        (async () => {
          try {
            return await axios
              .post(`${apiURL}/ai-agent/settings/save`, data, {
                withCredentials: true,
              })
              .then(
                (response) => {
                  return response.data;
                },
                (error) => {
                  console.log(error);
                  return false;
                }
              );
          } catch (e) {
            console.log("err", e);
            reject(e);
          }
        })()
      );
    });
  }
}

export default CompanyService;
