<template>
  <div class="integration-card-wrapper">
    <!--START: App Card-->
    <div class="app-card">
      <!--START: App details-->
      <div class="app-details">
        <img
          src="@/assets/images/apps/zendesk.jpg"
          class="app-logo"
          alt="Zendesk App"
        />
        <div>
          <h3>Zendesk</h3>
          <p>Integrate your Zendesk for ticket creations and agent handovers</p>
        </div>
      </div>
      <!--END: App details-->

      <!--START: Actions-->
      <button
        v-if="!app.credentials.domainName"
        class="btn btn-grey btn-small"
        @click="showAddIntegration"
      >
        Connect
      </button>
      <!--START: Connected Status-->
      <div v-else class="connected-account">
        <a
          :href="`https://${app.credentials.domainName}.zendesk.com`"
          target="_blank"
          class="account-integrated"
        >
          <unicon name="link"></unicon>
          <span>{{ app.credentials.domainName }}.zendesk.com</span>
        </a>

        <div class="actions-wrapper">
          <button
            class="btn btn-text btn-small btn-no-padding"
            @click="activateAiAgent"
          >
            <span>Activate AI Agent</span>
          </button>
          <button
            class="btn btn-text btn-small btn-no-padding"
            @click="showConfirmationModal"
          >
            <span>Disconnect account</span>
          </button>
          <FormBuilder
            :fields="fields"
            @fieldChanged="fieldChanged"
          ></FormBuilder>
        </div>
      </div>
      <!--EMD: Connected Status-->

      <!--START: Actions-->
    </div>
    <!--END: App Card-->

    <!--START: Add App-->
    <AddApp
      :show="showOptions.addIntegration"
      @appAdded="initApp"
      @closeModal="closeModal"
    ></AddApp>
    <!--END: Add App-->

    <!--START: Confirmation Modal-->
    <ConfirmationModal
      :fields="confirmationFields"
      @primaryEvent="disconnectApp"
      @secondaryEvent="closeModal"
    ></ConfirmationModal>

    <div
      class="bg-overlay light"
      :class="{ show: confirmationFields.show }"
      @click="closeModal"
    ></div>
    <!--END: Confirmation Modal-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
      @closeModal="closeModal"
      class="notification-message"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
    
<script>
// Import libraries
import _ from "lodash";

// Importing components
import FormBuilder from "@/components/form/FormBuilder";
import ConfirmationModal from "@/components/modals/ConfirmationModal";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";

import AddApp from "./AddApp";

// Importing Services
import { CompanyService } from "@/services";

export default {
  name: "ZendeskIntegration",
  data() {
    return {
      showOptions: {
        lineLoader: false,
        addIntegration: false,
      },
      confirmationFields: {
        show: false,
        title: "Disconnect Zendesk?",
        type: "delete",
        modalType: "fixed",
        message:
          "This action cannot be undone. The documents will be deleted from the knowledge base.",
        iconType: "exclamation",
        confirmAction: true,
        confirmValue: null,
        primaryBtn: {
          title: "Yes. Delete",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
      fields: {
        isActive: {
          type: "toggle",
          title: "Active",
          additionalClass: "large-toggle toggle-left",
          value: true,
        },
      },
      status: {
        show: false,
        status: "success",
        title: "Zendesk disconnected",
      },
    };
  },
  props: {},
  mounted() {},
  components: {
    FormBuilder,
    ConfirmationModal,
    NotificationMessage,
    LineLoader,
    AddApp,
  },
  computed: {
    // Get the app
    app() {
      let app = {};
      if (this.company?.integrations?.zendesk)
        app = this.company?.integrations?.zendesk;
      return app;
    },

    company() {
      return this.$store.getters.company;
    },

    // Check if the app is connected
    isConnected() {
      return !this.app.isActive && _.isEmpty(this.app.credentials);
    },
  },
  async created() {
    this.initApp();
  },
  methods: {
    // Initialise the app
    initApp() {
      this.fields.isActive.value = this.app?.isActive;
    },

    // Show shopify app modal
    showAddIntegration() {
      this.showOptions.addIntegration = true;
    },

    // Activate AI agent
    async activateAiAgent() {
      const data = { productName: "aiAgent", options: {} };
      const response = await CompanyService.ActivateProduct(data);
      console.log(response);
    },

    // Change the status of the app
    async changeStatus() {
      this.showOptions.lineLoader = true;
      const data = { ...this.fields };
      var isFormValid = this.validateForm(data);

      if (isFormValid) {
        const payload = this.constructPayload(this.parseData(data));
        const response = await CompanyService.UpdateIntegration(payload);

        if (!response.hasError) {
          this.showStatusMessage(this.status, 2500, response.message);
          this.$store.commit("updateCompany", response.data);
        }
      }

      this.showOptions.lineLoader = false;
    },

    // Disconnect app
    async disconnectApp() {
      this.closeModal();
      this.showOptions.lineLoader = true;

      const response = await CompanyService.RemoveIntegration({
        app: "zendesk",
      });

      // Check for errors
      if (!response.hasError) {
        this.$store.commit("updateCompany", response.data);
        this.showStatusMessage(this.status, 2500, response.message);
      } else this.showErrorMessage(this.status, response.message);

      this.showOptions.lineLoader = false;
    },

    // Construct the payload for the controller
    constructPayload(data) {
      const app = {
        app: "zendesk",
        isActive: data.isActive,
      };

      return this.sanitizeObject(app);
    },

    // Show the confirmation modal to disconnect Shopify
    showConfirmationModal() {
      this.confirmationFields.show = true;
    },

    // Event when the field is changed
    fieldChanged() {
      this.changeStatus();
    },

    // Close the add modal
    closeModal() {
      this.confirmationFields.show = false;
      this.showOptions.addIntegration = false;
    },
  },
};
</script>
    
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.alert-message {
  margin-bottom: 0.5rem;
}

.progress-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;

  span {
    color: $paragraphColor;
    font-size: $smallerFontSize;
  }

  .unicon {
    /deep/ svg {
      height: auto;
      width: 1rem;
      fill: $greyColor;
      opacity: $lightOpacity;
      transform-origin: center center;
      margin-right: 0.5rem;
      animation: spinner linear forwards infinite 1s;
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.notification-message {
  position: fixed;
}

.actions-wrapper {
  .btn {
    margin-right: 1rem;
  }
}
</style>