<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div
      class="header-wrapper"
      :class="{ 'show-shadow': showOptions.headerShadow }"
    >
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <button
        class="btn btn-small btn-primary"
        :disabled="showOptions.disableButton"
        @click="saveAiAgentSettings"
      >
        Save Changes
      </button>
    </div>
    <!--END: Header Wrapper-->

    <div ref="settings" class="settings-main" @scroll="settingsScroll">
      <!--START: Settings Form-->
      <div class="settings-wrapper">
        <!--START: Form Content-->
        <div class="settings-form-wrapper">
          <SettingsForm
            :fields="fields.fields"
            :content="fields.content"
            @fieldChanged="fieldChanged"
          ></SettingsForm>
        </div>
        <!--END: Form Content-->
      </div>
      <!--END: Settings Form-->

      <!--START: Widget status-->
      <div class="widget-status-wrapper">
        <div
          class="widget-status"
          :class="{
            'widget-connected': company.products.aiAgent.widgetConnected,
          }"
        >
          {{
            company.products.aiAgent.widgetConnected
              ? "Widget connected"
              : "No widget found"
          }}
        </div>
        <div v-if="company.widgetConnected" class="widget-status-info-wrapper">
          <h3>Zendesk widget not connected</h3>
          <p>
            Your Zendesk doesn't have the Macha widget added. Don't worry, your
            AI agent will work fine if activated. However, you need the widget
            if you want to add drafts to your tickets instead of auto-replies.
          </p>
        </div>
        <div v-else class="widget-status-info-wrapper">
          <h3>Zendesk widget connected</h3>
          <p>The Macha widget is successfully connected to Zendesk.</p>
        </div>
      </div>
      <!--END: Widget status-->
    </div>

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
      @closeModal="closeModal"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Unsaved Changes Modal -->
    <UnsavedChangesModal
      :show="showOptions.unsavedChanges"
      @primaryEvent="continueRoute"
      @secondaryEvent="closeModal"
    ></UnsavedChangesModal>
    <!--END: Unsaved Changes Modal -->
  </div>
</template>
              
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import LineLoader from "@/components/loaders/LineLoader";
import SettingsForm from "@/components/form/SettingsForm";
import NotificationMessage from "@/components/modals/NotificationMessage";
import UnsavedChangesModal from "@/components/modals/UnsavedChangesModal";

// Importing Services
import { CompanyService } from "@/services";

export default {
  name: "AiSettings",
  props: {},
  computed: {
    // Get the company
    company() {
      return this.$store.getters.company;
    },
  },
  data() {
    return {
      title: "AI Settings",
      showOptions: {
        lineLoader: false,
        showShadow: false,
        preLoader: true,
        unsavedChanges: false,
        headerShadow: false,
        disableButton: true,
      },
      regularHeader: {
        title: "AI Settings",
        description: "Activate the AI agent on your Zendesk",
      },
      fields: {
        content: {
          title: "Activate AI agent for Zendesk",
          description:
            "The AI agent will start working on tickets you've specified in the topics",
        },
        fields: {
          status: {
            type: "toggle",
            title: "Activate AI agent",
            additionalClass: "large-toggle",
            value: false,
          },
        },
      },
      initData: {},
      dataChanged: false,

      status: {
        show: false,
        status: "success",
        title: "AI agent saved",
      },
      saveInProgress: false,
    };
  },
  components: {
    RegularHeader,
    LineLoader,
    SettingsForm,
    NotificationMessage,
    UnsavedChangesModal,
  },
  async created() {
    this.populateFields();
  },

  methods: {
    // Save AI agent
    async saveAiAgentSettings() {
      if (!this.saveInProgress) {
        this.saveInProgress = true;
        this.showOptions.lineLoader = true;

        // Check if the form has valid input
        const data = {
          ...this.fields.fields,
        };
        var isFormValid = this.validateForm(data);
        if (isFormValid) {
          const formData = this.constructPayload();
          const response = await CompanyService.SaveAiAgentSettings(formData);

          // Check for errors
          if (!response.hasError) {
            this.$store.commit("updateCompany", response.data);
            this.populateFields();
            this.showStatusMessage(this.status, 2500);
          } else this.showErrorMessage(this.status, response.message);
        }

        this.showOptions.lineLoader = false;
        this.saveInProgress = false;
      }
    },

    // Populate the fields
    populateFields() {
      const { aiAgent } = this.company.products;
      const { status } = this.fields.fields;

      if (aiAgent?.status === "active") {
        status.value = true;
      } else status.value = false;

      this.initFormData();
    },

    // Initialise the form data for comparisons
    initFormData() {
      const data = {
        ...this.fields.fields,
      };

      this.disableSaveButton(true);
      this.initData = _.cloneDeep(data);
    },

    // Show the modal header options
    disableSaveButton(status) {
      this.dataChanged = !status;
      this.showOptions.disableButton = status;
    },

    // Update persona when fields change
    fieldChanged() {
      const data = {
        ...this.fields.fields,
      };
      this.disableSaveButton(this.isDataEqual(this.initData, data));
    },

    // Construct the payload for the controller
    constructPayload() {
      return this.parseData(this.fields.fields);
    },

    // Event on settings scroll
    settingsScroll() {
      const scrollTop = this.$refs.settings.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    // Close all the modals
    closeModal() {
      this.status.show = false;
      this.showOptions.unsavedChanges = false;
    },

    // Discard all the changes
    discardChanges() {
      this.personaFields.fields = _.cloneDeep(this.initData.personaFields);
      this.disableSaveButton(true);
      this.closeModal();
    },

    // Discard the changes and move to the selected route
    continueRoute() {
      this.closeModal();
      this.discardChanges();
      if (this.toRoute != null) this.$router.push({ path: this.toRoute.path });
    },
  },
  // Check if there are any changes to be saved
  beforeRouteLeave(to, from, next) {
    if (this.dataChanged) {
      this.toRoute = to;
      this.showOptions.unsavedChanges = true;
    } else {
      next();
    }
  },
};
</script>
              
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.header-wrapper {
  padding-bottom: 1.5rem;
  margin-bottom: 0 !important;
  border-bottom: 1px solid darken($rowBorderColor, 2.5%);
  position: relative;
  z-index: 2;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.settings-main {
  overflow-y: scroll;
  padding: 1.5rem 1rem;
  flex: 1;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

/deep/ .profile-image-cropper {
  width: 45vw;
}

.widget-status-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .widget-status {
    padding: 0.5rem 1.15rem;
    border-radius: 0.75rem;
    background-color: $warningYellowBackgroundColor;
    border: 1px solid $warningYellowBorderColor;
    color: $warningYellowColor;
    font-weight: $mediumFontWeight;
    font-size: $smallFontSize;

    &.widget-connected {
      background-color: lighten($emeraldColor, 35%);
      color: darken($emeraldColor, 35%);
      border-color: $emeraldColor;
    }
  }

  .widget-status-info-wrapper {
    margin-left: 1rem;

    h3 {
      font-size: $normalFontSize;
    }
    p {
      font-size: $smallerFontSize;
    }
  }
}
</style>