<template>
  <!--START: Answer Rule-->
  <div
    v-if="show"
    class="modal modal-large no-overflow"
    :class="[{ show: show }, { 'has-ticket': ticket.id }]"
  >
    <!--START: Header-->
    <ModalHeader :title="title" @closeModal="closeModal"></ModalHeader>
    <!--END: Header-->

    <!--START: Modal Body-->
    <div class="modal-body">
      <div class="main-wrapper" ref="documentContent" @scroll="contentScroll">
        <!--START: Ticket wrapper-->
        <div class="ticket-wrapper">
          <!--START: Search Wrapper-->
          <div v-if="showOptions.search">
            <div class="search-info-wrapper">
              <h3 class="search-title">Use Zendesk ticket</h3>
              <p class="search-description">
                Use an existing ticket from your Zendesk to test how the AI
                responds.
              </p>
            </div>
            <div class="search-block">
              <div class="search-wrapper" @keyup.enter="searchDocuments">
                <unicon name="search"></unicon>
                <FormBuilder :fields="fields"></FormBuilder>
              </div>
              <button
                class="btn btn-primary btn-small"
                @click="getZendeskTicket"
              >
                Get ticket
              </button>
            </div>
          </div>
          <div v-else class="ticket-options">
            <div class="notification-alert">
              <unicon name="comment-alt"></unicon>
              <span>Only the first message of this ticket will be used</span>
            </div>
            <div class="search-retry" @click="showSearch">
              <unicon name="redo"></unicon>
              <span>Try with another ticket</span>
            </div>
          </div>
          <!--END: Search Wrapper-->

          <!--START: Ticket Wrapper-->
          <div
            v-if="showOptions.ticket && ticket.id"
            class="ticket-details-wrapper"
          >
            <div class="card">
              <div class="ticket-subject">
                <div class="sender-details">
                  <span class="name">From {{ ticket.requester.name }}</span>
                  <span class="email">{{ ticket.requester.email }}</span>
                  <a
                    :href="`https://${zendeskDomain}.zendesk.com/agent/tickets/${ticket.id}`"
                    target="_blank"
                    class="ticket-id"
                  >
                    <unicon name="link"></unicon>
                    <span>{{ ticket.id }}</span></a
                  >
                  <span class="ticket-channel">{{ ticket.via?.channel }}</span>
                </div>
                <div class="ticket-subject-wrapper">
                  <h3 class="ticket-subject">{{ ticket.subject }}</h3>
                </div>
                <div class="ticket-comments">
                  <div v-if="showOptions.ticketMessage" class="comment-wrapper">
                    <div
                      class="comment"
                      v-html="ticket.comments[0].html_body"
                    ></div>
                  </div>
                  <div
                    v-else
                    class="show-ticket-message"
                    @click="showTicketMessage"
                  >
                    <unicon name="plus"></unicon>
                    <span>Show message</span>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="answer">
              <!--START: Answer-->
              <div
                class="ai-answer"
                :class="{ error: answer.answerConfidence?.score < 4 }"
              >
                <div class="ai-avatar">
                  <unicon name="bolt"></unicon>
                  <div>
                    <h3>Macha AI</h3>
                    <span>Answer generated by AI</span>
                  </div>
                </div>
                <div class="answer" v-html="answer.answer"></div>
              </div>
              <!--END: Answer-->

              <!--START: Message Notification-->
              <div
                v-if="answer.answerConfidence?.score < 4"
                class="action-step error answer-action"
              >
                <unicon name="times"></unicon>
                <p>
                  This answer would not be used because it has a low confidence
                  score
                </p>
              </div>
              <!--END: Message Notification-->

              <div class="actions-wrapper">
                <FormBuilder
                  :fields="messagesFields"
                  class="messages-form"
                ></FormBuilder>
                <button
                  class="btn btn-primary btn-small btn-generate"
                  :disabled="runningPlayground"
                  @click="runTest"
                >
                  <unicon name="redo"></unicon>
                  <span>Run test again</span>
                </button>
              </div>
            </div>
            <div v-else class="actions-wrapper">
              <FormBuilder
                :fields="messagesFields"
                class="messages-form"
              ></FormBuilder>
              <button
                class="btn btn-primary btn-small btn-generate"
                :disabled="runningPlayground"
                @click="runTest"
              >
                <unicon name="bolt"></unicon>
                <span>Run test</span>
              </button>
            </div>
          </div>
          <div v-else-if="showOptions.ticket" class="ticket-error">
            <svg
              :data-src="require('@/assets/images/doodles/error.svg')"
              height="auto"
              width="45px"
              class="error-asset"
            ></svg>
            <h3>Ticket {{ ticketID }} not found</h3>
            <span
              >We couldn't find a ticket with this ID. Look for another
              ticket</span
            >
          </div>
          <!--END: Ticket Wrapper-->
        </div>
        <!--END: Ticket wrapper-->

        <div v-if="ticket.id" class="ticket-analysis-wrapper">
          <!--START: Ticket Analysis-->
          <div v-if="aiResponse._id" class="ticket-analysis card">
            <div class="analysis-wrapper">
              <span class="timestamp">
                Ticket analysis -
                {{ moment(analysis.createdAt).format("hh:MM A") }}
              </span>
              <div
                class="action-step green"
                :class="{
                  error: aiResponse.analysis.topic === 'Not Applicable',
                }"
              >
                <unicon
                  :name="
                    aiResponse.analysis.topic === 'Not Applicable'
                      ? 'times'
                      : 'check'
                  "
                ></unicon>
                <p>
                  Categorised as <span class="topic">{{ analysis.topic }}</span>
                </p>
              </div>
              <div
                class="action-step green"
                :class="{ error: analysis.ignoreTicket }"
              >
                <unicon
                  :name="!analysis.ignoreTicket ? 'check' : 'times'"
                ></unicon>
                <p>
                  <span class="filter">{{
                    analysis.ignoreTicket ? "Ignore Ticket" : "Process Ticket"
                  }}</span>
                  {{ analysis.ignoreTicketExplanation }}
                </p>
              </div>
              <div class="action-step short-message">
                <div class="language-step">
                  <unicon name="english-to-chinese"></unicon>
                  <label><span>Customer language -</span></label>
                  <p class="shortened-message">
                    {{ analysis.customerLanguage }}
                  </p>
                </div>
              </div>
              <div class="action-step short-message">
                <div>
                  <label>Summarized interpretation by AI</label>
                  <p class="shortened-message">
                    {{ analysis.shortenedCustomerMessage }}
                  </p>
                </div>
              </div>
            </div>

            <div v-if="answer" class="answer-analysis-wrapper">
              <span class="timestamp">
                Answer analysis -
                {{ moment(answer.createdAt).format("hh:MM A") }}
              </span>
              <div
                class="action-step green"
                :class="{ error: answer.answerConfidence?.score < 4 }"
              >
                <unicon
                  :name="
                    answer.answerConfidence?.score >= 4 ? 'check' : 'times'
                  "
                ></unicon>
                <p>
                  <span class="topic">{{
                    answer.answerConfidence?.score
                  }}</span>
                  - Confidence Score
                </p>
              </div>
              <div class="action-step short-message">
                <div>
                  <label>Confidence score explanation</label>
                  <p class="shortened-message">
                    {{ answer.answerConfidence?.explanation }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--END: Ticket Analysis-->

          <!--START: Zero State -->
          <ZeroState
            v-else-if="!runningPlayground"
            class="zero-state"
            :imageURL="noAnalysis.imageURL"
            :title="noAnalysis.title"
            :message="noAnalysis.message"
            :buttonTitle="noAnalysis.buttonTitle"
            @eventHandler="runTest"
          ></ZeroState>
          <!--END: Zero State -->

          <!--START: AI Animation-->
          <div v-else class="ai-animation writing show">
            <span></span>
            <span></span>
            <span></span>
            <b>Running playground</b>
            <label>This will take a minute</label>
          </div>
          <!--END: AI Animation-->
        </div>
      </div>
    </div>
    <!--END: Modal Body-->

    <!--START: Loader-->
    <LineLoader
      :show="showOptions.lineLoader"
      :class="{ bottom: !showOptions.modalActions }"
    ></LineLoader>
    <!--END: Loader-->
  </div>
  <!--END: Answer Rule-->
</template>
              
<script>
// Importing components
import ModalHeader from "@/components/modals/ModalHeader";
import LineLoader from "@/components/loaders/LineLoader";
import FormBuilder from "@/components/form/FormBuilder";
import ZeroState from "@/components/misc/ZeroState";

// Importing Services
import { AiAgentService } from "@/services";

export default {
  name: "TopicPlayground",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    show: {
      immediate: true,
      deep: true,
      handler() {},
    },
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },

    zendeskDomain() {
      return this.company.integrations?.zendesk?.credentials?.domainName;
    },

    ticketID() {
      return this.fields.search.value;
    },

    taskID() {
      if (this.analysis) return this.analysis.taskID;
      else return false;
    },

    analysis() {
      if (this.aiResponse?.aiAnalysis?.length > 0) {
        const analysisLength = this.aiResponse.aiAnalysis?.length;
        return this.aiResponse.aiAnalysis[analysisLength - 1];
      }

      return false;
    },

    answer() {
      const selectedAnswer = this.aiResponse?.responses?.find(
        (r) => r.taskID === this.taskID
      );

      if (selectedAnswer) return selectedAnswer;
      return false;
    },
  },
  data() {
    return {
      title: "AI Playground",
      showOptions: {
        lineLoader: false,
        showShadow: false,
        disableButton: true,
        ticket: false,
        search: true,
        ticketMessage: true,
      },
      fields: {
        search: {
          type: "text",
          placeholder: "Search by Zendesk ticket ID",
          required: false,
          hasError: false,
          additionalClass: "small-input",
          value: "",
        },
      },
      messagesFields: {
        useFirstMessage: {
          type: "toggle",
          title: "Use only first message",
          additionalClass: "large-toggle",
          value: true,
        },
      },
      noAnalysis: {
        imageURL: "services.jpg",
        title: "Run Test with Ticket",
        message: "Have the AI analyze this ticket to see how it responds",
        buttonTitle: "Run test with ticket",
      },
      runningPlayground: false,
      ticket: {},
      aiResponse: {},
    };
  },
  components: {
    ModalHeader,
    LineLoader,
    FormBuilder,
    ZeroState,
  },
  created() {},

  methods: {
    // Get tickets from Zendesk
    async getZendeskTicket() {
      this.resetModal();
      this.showOptions.ticket = false;
      this.showOptions.lineLoader = true;

      const ticketID = this.fields.search.value;
      const response = await AiAgentService.GetZendeskTicket({ ticketID });

      if (!response.hasError) {
        this.ticket = response.data;
        this.showOptions.ticket = true;
        this.showOptions.search = false;
      }

      this.showOptions.lineLoader = false;
    },

    // Show search functionality
    showSearch() {
      this.showOptions.search = true;
    },

    // Run the test
    async runTest() {
      if (!this.runningPlayground) {
        this.runningPlayground = true;
        this.showOptions.lineLoader = true;

        const data = this.constructPayload();
        const response = await AiAgentService.RunPlaygroundTest(data);

        this.runningPlayground = false;
        this.showOptions.lineLoader = false;

        if (!response.hasError) {
          this.aiResponse = response.data;
          this.showOptions.ticketMessage = false;
        }
      }
    },

    // Show ticket message
    showTicketMessage() {
      this.showOptions.ticketMessage = true;
    },

    // Construct the payload for the controller
    constructPayload() {
      const data = {
        ticket: this.ticket,
        useFirstMessage: this.messagesFields.useFirstMessage.value,
      };

      return data;
    },

    // Event on chat scroll
    contentScroll() {
      if (this.$refs.documentContent.scrollTop > 0)
        this.showOptions.showShadow = true;
      else this.showOptions.showShadow = false;
    },

    // Reset booking modal
    resetModal() {
      this.showOptions.lineLoader = false;
      this.showOptions.showShadow = false;
      this.showOptions.ticketMessage = true;
      this.ticket = {};
      this.aiResponse = {};
    },

    // Hide modal
    closeModal() {
      this.showOptions.lineLoader = false;
      this.$emit("closeModal");
    },
  },
};
</script>
              
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

/deep/ .modal-header {
  padding: 0.5rem 1rem;

  /deep/ .unicon svg {
    background-color: lighten($blackColor, 15%);
    fill: $whiteColor;
  }
}

/deep/ .modal-actions {
  flex-direction: row;
  justify-content: center;
}

/deep/ .zero-state {
  .btn {
    background-color: $purpleColor;
    color: $whiteColor;

    .unicon {
      display: none;
    }
  }
}

.modal.has-ticket {
  width: 80vw !important;
  max-height: 90vh !important;
}

.modal-body {
  position: relative;
  padding: 0 !important;
  background-color: darken($whiteColor, 2.5%);
  flex: 1;
  overflow: hidden;
  overflow-y: hidden !important;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.title-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem 2rem;
  z-index: 5;

  &.show-shadow {
    box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
  }

  .title-info {
    flex: 1;
    .title {
      font-size: $mediumFontSize;
      text-transform: capitalize;
      margin-bottom: 0.15rem;
    }

    p {
      color: $paragraphColor;
      font-size: $smallFontSize;
    }
  }
}

.main-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2rem 3rem;
  background-size: 22px 22px;
  border-top: 1px solid $greyBorderColor;
  overflow-y: scroll;

  &.show-shadow {
    box-shadow: 0 0.5rem 0.7rem -0.5rem $boxShadowColor;
  }
}

.ticket-wrapper {
  width: 55%;
  flex: 1;
}

.modal.has-ticket {
  .ticket-wrapper {
    margin-right: 2rem;
  }
}

.ticket-analysis-wrapper {
  width: calc(45% - 2rem);
}

.answer-analysis-wrapper {
  margin-top: 2rem;
}

.search-block {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-wrapper {
  position: relative;
  margin-right: 1rem;
  flex: 1;

  .unicon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1ch;
    z-index: 2;

    /deep/ svg {
      fill: $greyColor;
      opacity: $lightOpacity;
      width: 1rem;
    }
  }

  /deep/ {
    .field-main-item {
      margin-bottom: 0;
    }

    input {
      padding-left: 2rem !important;
    }
  }
}

.ticket-details-wrapper {
  margin-top: 1rem;
}

.search-info-wrapper {
  margin-bottom: 1rem;

  .search-title {
    font-size: $mediumFontSize;
  }

  .search-description {
    font-size: $smallFontSize;
  }
}

.ticket-error {
  text-align: center;
  margin-top: 3rem;

  .error-asset {
    width: 5rem;
  }

  h3 {
    font-size: $normalFontSize;
  }

  span {
    font-size: $smallFontSize;
    color: $greyColor;
  }
}

.sender-details {
  display: flex;
  flex-direction: row;
  align-items: baseline;

  .name {
    font-size: $smallFontSize;
    font-weight: $mediumFontWeight;
    color: darken($greyColor, 5%);
    margin-left: 0;
  }

  .email {
    position: relative;
    flex: 1;
    margin-left: 1rem;
    padding-left: 1rem;

    font-size: $smallestFontSize;
    font-weight: $normalFontWeight;
    color: darken($greyColor, 5%);

    &::before {
      position: absolute;
      content: "";
      left: 0;
      background-color: $purpleColor;
      border-radius: 50%;
      height: 5px;
      width: 5px;
      transform: translateY(-50%);
      top: 50%;
    }
  }

  .ticket-id {
    padding: 0.15rem 0.75rem;
    border-radius: 0.5rem;
    background-color: $darkBlackColor;
    color: $whiteColor;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background-color: $blueColor;
    }

    /deep/ .unicon svg {
      height: auto;
      width: 0.65rem;
      fill: $whiteColor;
      margin-right: -0.15rem;
    }
  }

  span {
    font-size: $smallestFontSize;
    margin-left: 0.5rem;

    &.ticket-channel {
      padding: 0.15rem 0.75rem;
      border-radius: 0.5rem;
      background-color: darken($whiteColor, 15%);
      color: darken($whiteColor, 65%);
    }
  }
}

.ticket-subject-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 0.25rem;

  .ticket-subject {
    font-size: 1.05rem;
  }
}

.comment {
  font-size: $smallFontSize;
  color: $greyColor;
  line-height: 1.4;
}

.btn-generate {
  width: calc(100%);
  margin: 1rem 0 0;
  background-color: $purpleColor;
  border: 1px solid lighten($purpleColor, 10%);
}

.action-step {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 0.5rem;

  /deep/ .unicon {
    margin-right: 0.5rem;
    transform: translateY(2.5px);

    svg {
      background-color: $greenColor;
      fill: darken($greenColor, 40%);
      border-radius: 50%;
      width: 0.95rem;
      height: auto;
    }
  }

  &.green {
    background: lighten($greenColor, 35%);
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid darken($greenColor, 5%);

    p {
      color: darken($greenColor, 40%);
      font-size: $smallFontSize;
    }
  }

  &.error {
    background: lighten($redColor, 35%);
    border: 1px solid darken($redColor, 5%);

    p {
      color: darken($redColor, 40%);
    }

    /deep/ .unicon svg {
      background-color: darken($redColor, 40%);
      fill: lighten($redColor, 40%);
    }
  }

  .topic,
  .filter {
    font-weight: $boldFontWeight;
  }

  .filter {
    display: block;
  }

  &.short-message {
    background: $whiteColor;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid darken($whiteColor, 15%);
    margin-top: 1rem;

    label {
      display: block;
      color: $darkBlackColor;
      font-size: $smallerFontSize;
      margin-bottom: 0.5rem;
    }

    p {
      color: $darkBlackColor;
      font-size: $smallFontSize;
      font-weight: $normalFontWeight;
      opacity: $mediumOpacity;
    }
  }
}

.timestamp {
  display: block;
  font-size: $smallestFontSize;
  color: $greyColor;
  margin-bottom: 0.5rem;
  opacity: $mediumOpacity;
  text-align: right;
}

.ai-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid lighten($darkBlackColor, 5%);

  .unicon /deep/ {
    margin-right: 0.75rem;

    svg {
      height: auto;
      width: 1.25rem;
      background: $purpleColor;
      border-radius: 0.5rem;
      padding: 0.25rem;
      fill: lighten($purpleColor, 55%);
    }
  }

  h3 {
    font-weight: $mediumFontWeight;
    font-size: $smallerFontSize;
    color: $whiteColor;
    margin-bottom: 0;
  }

  span {
    color: darken($whiteColor, 45%);
    font-size: $smallerFontSize;
  }
}

.ai-answer {
  background: $darkBlackColor;
  border-radius: 1rem;
  font-size: $smallFontSize;
  line-height: 1.4;
  padding: 1.5rem 2rem;
  color: lighten($darkBlackColor, 75%);
  border: 4px solid darken($greenColor, 5%);
  margin-top: 2rem;

  /deep/ {
    a {
      color: $blueColor;
    }

    ul {
      margin-left: 1.5rem;
      margin-top: 1rem;

      li {
        list-style-type: disc;
      }
    }
  }

  &.error {
    border: 4px solid darken($redColor, 5%);
  }
}

.answer-action {
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;
  justify-content: center;
  width: fit-content;
  margin: 1rem auto 0;

  p {
    font-size: $smallFontSize;
  }
}

.show-ticket-message {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  font-size: $smallerFontSize;
  color: $greyColor;
  opacity: 0.65;
  width: fit-content;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  /deep/ .unicon svg {
    fill: $greyColor;
    height: auto;
    width: 1rem;
    margin-right: 0.25rem;
    transform: translateY(1px);
  }
}

.ticket-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -0.75rem;
}

.notification-alert {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.15rem 0.75rem;
  border-radius: 0.5rem;
  margin-right: 0.5rem;

  background-color: $warningYellowBackgroundColor;
  border: 1px solid darken($warningYellowBackgroundColor, 45%);

  span {
    color: $warningYellowColor;
    font-size: $smallerFontSize;
  }

  /deep/ .unicon svg {
    fill: $warningYellowColor;
    height: auto;
    width: 0.75rem;
    margin-right: 0.25rem;
    transform: translateY(2px);
  }
}

.search-retry {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .unicon /deep/ svg {
    width: 0.65rem;
    transform: translateY(1.5px);
    margin-right: 0.25rem;
    fill: $greyColor;
  }

  span {
    font-size: $smallestFontSize;
    color: $greyColor;
  }

  &:hover {
    .unicon /deep/ svg {
      fill: $purpleColor;
    }

    span {
      color: $purpleColor;
    }
  }
}

.ai-animation {
  text-align: center;
  z-index: 3;
  transition: all 0.3s;

  &.show {
    z-index: 2;
    transform: translateY(10rem);
  }

  b {
    display: block;
    margin-top: 0.5rem;
    font-size: $smallFontSize;
  }

  label {
    display: block;
    margin-top: 0.5rem;
    font-size: $smallerFontSize;
    color: lighten($darkBlackColor, 35%);
  }

  span {
    display: block;
    background-color: $purpleColor;
    box-shadow: 0 1px 8px -1px lighten($purpleColor, 30%);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -2.5rem;
    transition: all 0.15s;

    &:nth-child(1) {
      left: calc(50% - 10px - 5px);
    }

    &:nth-child(2) {
      left: calc(50%);
    }

    &:nth-child(3) {
      left: calc(50% + 10px + 5px);
    }
  }

  &.writing {
    span {
      height: 15px;
      width: 7.5px;
      border-radius: 5px;
      animation: writing ease-out infinite forwards 0.5s;

      &:nth-child(1) {
        animation-delay: 0s;
      }

      &:nth-child(2) {
        animation-delay: 0.1s;
      }

      &:nth-child(3) {
        animation-delay: 0.2s;
      }
    }
  }

  @keyframes writing {
    0% {
      transform: translateY(-50%);
    }

    25% {
      transform: translateY(calc(-50% - 5px));
    }

    75% {
      transform: translateY(calc(-50% + 5px));
    }
  }
}

.actions-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .btn {
    width: calc(100% - 20rem);
  }

  /deep/ {
    .field-main-item {
      margin-bottom: 0;
    }
  }

  .messages-form {
    flex: 1;
    margin-right: 5rem;
  }
}

.language-step {
  display: flex;
  flex-direction: row;
  align-items: center;

  .unicon /deep/ svg {
    background: transparent;
  }

  label {
    margin-bottom: 0 !important;
    margin-right: 0.25rem;
  }
}
</style>