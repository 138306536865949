<template>
  <div class="data-table">
    <!--START: Documents List-->
    <ul class="grid-table">
      <li
        class="grid-row grid-header"
        :class="{ 'grid-header-shadow': showOptions.headerShadow }"
      >
        <span class="grid-column">Name</span>
        <span class="grid-column">Intents</span>
        <span class="grid-column">Response</span>
        <span class="grid-column">Channels</span>
        <span class="grid-column">Status</span>
        <span class="grid-column">Actions</span>
      </li>
    </ul>
    <ul
      class="grid-table grid-item-clickable"
      ref="table"
      @scroll="tableScroll"
    >
      <li
        v-for="(t, index) in topics"
        :key="index"
        class="grid-row"
        @click="gotoItem(t._id)"
      >
        <div class="grid-column">
          <h3 class="name">{{ t.title }}</h3>
        </div>

        <div class="grid-column">
          <span
            class="intent-count"
            :class="{ 'no-intents': t.intents.length === 0 }"
            >{{ t.intents.length }}</span
          >
        </div>

        <div class="grid-column">
          <span class="response-type">{{
            getResponseType(t.responseType)
          }}</span>
        </div>

        <div class="grid-column">
          <div v-for="c in t.activeChannels" :key="c._id" class="channel">
            <span>{{ c.channelType }}</span>
          </div>
        </div>

        <div class="grid-column">
          <span class="status" :class="{ inactive: !t.isActive }">{{
            t.isActive ? "Active" : "Inactive"
          }}</span>
        </div>

        <div class="grid-column grid-actions">
          <button
            class="btn btn-icon btn-small"
            @click.stop="showIntents(t._id)"
          >
            <unicon name="eye"></unicon>
            <span>Intents</span>
          </button>
          <button class="btn btn-icon btn-small">
            <unicon name="pen"></unicon>
            <span>Edit</span>
          </button>
          <button
            class="btn btn-icon btn-delete"
            @click.stop="showDeleteModal(t._id, t.title)"
          >
            <unicon name="trash-alt"></unicon>
          </button>
        </div>
      </li>
    </ul>
    <!--END: Documents List-->

    <!--START: Documents Count-->
    <span class="grid-count"
      >Showing {{ topics.length }}
      {{ topics.length == 1 ? "topic" : "topics" }}</span
    >
    <!--END: Documents Count-->

    <!--START: Confirmation Modal-->
    <ConfirmationModal
      :fields="confirmationFields"
      @primaryEvent="deleteItem"
      @secondaryEvent="closeModal"
    ></ConfirmationModal>

    <div
      class="bg-overlay light"
      :class="{ show: confirmationFields.show }"
      @click="closeModal"
    ></div>
    <!--END: Confirmation Modal-->
  </div>
</template>
              
<script>
// Importing Components
import ConfirmationModal from "@/components/modals/ConfirmationModal";

export default {
  name: "TopicsTable",
  props: {
    topics: Array,
    customReplies: Array,
  },
  data() {
    return {
      showOptions: {
        headerShadow: false,
      },
      deleteID: null,
      confirmationFields: {
        show: false,
        title: "",
        type: "delete",
        modalType: "fixed",
        message:
          "This action cannot be undone. All intents for this topic will be deleted.",
        iconType: "exclamation",
        confirmAction: true,
        confirmValue: null,
        primaryBtn: {
          title: "Yes. Delete",
        },
        secondaryBtn: {
          title: "Cancel",
        },
      },
    };
  },
  computed: {},
  components: {
    ConfirmationModal,
  },
  async created() {},
  methods: {
    // Get response type
    getResponseType(response) {
      const responseTypes = [
        { value: "draft", title: "Create draft" },
        { value: "auto-reply", title: "Auto reply" },
        { value: "default", title: "Use topic response" },
      ];

      const selectedResponse = responseTypes.find((t) => t.value === response);
      return selectedResponse.title;
    },

    // Event on table scroll
    tableScroll() {
      const scrollTop = this.$refs.table.scrollTop;

      // Check if header needs shadow
      if (scrollTop > 0) this.showOptions.headerShadow = true;
      else this.showOptions.headerShadow = false;
    },

    // Goto view a particular topic
    gotoItem(itemID) {
      this.$router.push({ path: `/ai-agent/topics/view/${itemID}` });
    },

    // Goto view a topic's intents
    showIntents(itemID) {
      this.$emit("showTopicIntents", itemID);
    },

    // Show the topic modal
    showDeleteModal(itemID, itemTitle) {
      this.deleteID = itemID;
      this.confirmationFields.confirmValue = itemTitle;
      this.confirmationFields.show = true;
      this.confirmationFields.title = `Delete "${itemTitle}"?`;
    },

    // Delete the topic
    async deleteItem() {
      this.$emit("deleteTopic", this.deleteID);
      this.closeModal();
    },

    // Close all the modals
    closeModal() {
      this.confirmationFields.show = false;
    },
  },
};
</script>
              
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";
.data-table {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  margin-bottom: 0.5rem;
}

.grid-table.grid-item-clickable {
  flex: 1;
  overflow-y: scroll;
}

.grid-column {
  width: 18%;
  text-align: center;

  &:first-child {
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:last-child {
    text-align: right;
  }

  &:nth-child(2) {
    width: 10%;
  }
}

.grid-actions {
  .btn {
    margin-left: 1.5rem;
  }
}

.name,
.intent-count,
.response-type {
  color: $greyColor;
  font-size: $smallerFontSize;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
}

.no-intents {
  opacity: 0.35;
}

.name {
  font-size: $smallFontSize;
  color: $blackColor;
  font-weight: $mediumFontWeight;
}

.channel span,
.status {
  position: relative;
  font-size: $smallestFontSize;
  font-weight: $mediumFontWeight;
  color: $greenMessageTextColor;
  background-color: lighten($greenMessageColor, 0%);
  border: 1px solid $greenMessageBorderColor;
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  text-transform: capitalize;

  &.inactive {
    color: $redMessageTextColor;
    background-color: lighten($redMessageColor, 5%);
    border: 1px solid $redMessageBorderColor;
  }
}

.channel {
  display: inline-block;
  margin: 0 0.15rem;

  span {
    text-transform: none;
    background-color: lighten($darkBlackColor, 10%);
    color: $whiteColor;
    border-color: lighten($darkBlackColor, 20%);
  }
}

.pre-loader-wrapper /deep/ {
  .buffer-hero,
  .buffer-title {
    height: 1rem;
    margin: 0;
  }

  .buffer-line,
  .buffer-category {
    display: none;
  }
}
</style>