import { render, staticRenderFns } from "./TopicsTable.vue?vue&type=template&id=79517c39&scoped=true"
import script from "./TopicsTable.vue?vue&type=script&lang=js"
export * from "./TopicsTable.vue?vue&type=script&lang=js"
import style0 from "./TopicsTable.vue?vue&type=style&index=0&id=79517c39&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79517c39",
  null
  
)

export default component.exports