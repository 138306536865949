<template>
  <div class="page-main flex-page">
    <!--START: Breadcrumb-->
    <div class="breadcrumb">
      <router-link to="/topics">Question Categories</router-link>
      <unicon name="angle-right"></unicon>
      <span>{{ topic.title }}</span>
    </div>
    <!--END: Breadcrumb-->

    <!--START: Header Wrapper-->
    <div class="header-wrapper">
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->
      <button class="btn btn-small btn-grey" @click="addIntent">
        Add Intent
      </button>
    </div>
    <!--END: Header Wrapper-->

    <!--START: Zero State -->
    <ZeroState
      v-if="showOptions.zeroState"
      class="zero-state"
      :imageURL="zeroStateData.imageURL"
      :title="zeroStateData.title"
      :message="zeroStateData.message"
      :buttonTitle="zeroStateData.buttonTitle"
      @eventHandler="addIntent"
    ></ZeroState>
    <!--END: Zero State -->

    <!--START: Intents Table-->
    <IntentsTable
      v-else
      :intents="intents"
      @intentSelected="showIntent"
      @deleteIntent="deleteIntent"
    ></IntentsTable>
    <!--END: Intents Table-->

    <!--START: Loader-->
    <PageLoader v-if="showOptions.pageLoader" :showLoader="true"></PageLoader>
    <!--END: Loader-->

    <!--START: View Intent-->
    <ViewIntent
      v-if="showOptions.viewIntent"
      :topicID="topicID"
      :intent="selectedIntent"
      :show="showOptions.viewIntent"
      @intentAdded="intentAdded"
      @closeModal="closeModal"
    ></ViewIntent>
    <!--END: View Intent-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
      
<script>
// Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import ZeroState from "@/components/misc/ZeroState";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PageLoader from "@/components/loaders/PageLoader";
import IntentsTable from "@/components/dashboard/tables/IntentsTable";
import ViewIntent from "@/components/dashboard/topics/ViewIntent";

// Importing Services
import { TopicService } from "@/services";

export default {
  name: "ViewIntents",
  data() {
    return {
      showOptions: {
        pageLoader: true,
        lineLoader: false,
        zeroState: false,
        viewIntent: false,
      },
      regularHeader: {
        title: "Topic Intents",
        description: "Intents for the AI to recognize under this topic",
      },
      zeroStateData: {
        imageURL: "services.jpg",
        title: "No intents found",
        message: "Intents help you build workflows for specific Topics",
        buttonTitle: "Add Intent",
      },
      status: {
        show: false,
        status: "success",
        title: "Document deleted",
      },
      topicID: null,
      topic: {},
      selectedIntent: {},
      intents: [],
    };
  },
  props: {},
  watch: {},
  components: {
    RegularHeader,
    ZeroState,
    NotificationMessage,
    LineLoader,
    PageLoader,
    IntentsTable,
    ViewIntent,
  },
  computed: {},
  async created() {
    await this.getIntents();
  },

  methods: {
    // Function to get intents
    async getIntents() {
      this.topicID = this.$route.params.topicID;
      this.showOptions.pageLoader = true;
      this.showOptions.zeroState = false;

      const data = { topicID: this.topicID };
      const response = await TopicService.GetTopic(data);

      // Check for errors
      if (!response.hasError) {
        this.topic = response.data;
        this.regularHeader.title = this.topic.title;
        this.intents = this.topic.intents;
        if (this.intents.length == 0) this.showOptions.zeroState = true;
      } else this.showErrorMessage(this.status, response.message);

      this.showOptions.pageLoader = false;
    },

    // Show intent modal
    addIntent() {
      this.showOptions.viewIntent = true;
    },

    // Show the selected intent
    async showIntent(intent) {
      this.selectedIntent = intent;
      this.addIntent();
    },

    // Intent updated
    intentAdded(intent) {
      let tempIntents = _.cloneDeep(this.intents);
      const index = this.intents.findIndex((i) => i._id === intent._id);

      if (index !== -1) tempIntents[index] = intent;
      else tempIntents.push(intent);

      this.intents.splice(0);
      this.intents = _.cloneDeep(tempIntents);
    },

    // Delete intent
    async deleteIntent(intentID) {
      this.showOptions.lineLoader = true;

      const data = { topicID: this.topicID, intentID };
      await TopicService.DeleteIntent(data);

      // Remove from the table
      const index = this.intents.findIndex((i) => i._id == data.intentID);
      this.intents.splice(index, 1);

      this.status.title = "Intent deleted";
      this.showOptions.lineLoader = false;
      this.showStatusMessage(this.status, 2500);

      if (this.intents.length === 0) this.showOptions.zeroState = true;
    },

    // Close all the modals
    closeModal() {
      this.selectedIntent = {};
      this.status.show = false;
      this.showOptions.viewIntent = false;
    },
  },
};
</script>
      
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

.header-wrapper {
  margin-bottom: 0.5rem !important;
}

.left-column {
  flex: 1;
}

.right-column {
  text-align: right;
}

.source-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-transform: capitalize;
  font-size: $smallerFontSize;
  margin-bottom: 0.5rem;
  color: $greyColor;

  img {
    width: 1.5rem;
    border-radius: 0.5rem;
    margin-right: 0.5rem;
  }
}

.zero-state {
  margin-top: 2.5rem;
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}

.actions-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;

  /deep/ {
    .field-main-item {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
}

.table-actions-wrapper {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 2rem;

  .document-count {
    flex: 1;
    font-weight: $mediumFontWeight;
    color: $paragraphColor;
    font-size: $smallFontSize;
    opacity: $lightOpacity;
  }

  .search-wrapper {
    position: relative;
    margin-right: 1.5rem;

    .unicon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 1ch;
      z-index: 2;

      /deep/ svg {
        fill: $greyColor;
        opacity: $lightOpacity;
        width: 1rem;
      }
    }

    /deep/ {
      .field-main-item {
        width: 18rem;
      }
      input {
        padding-left: 2rem;
      }
    }
  }

  /deep/ {
    .field-main-item {
      margin-bottom: 0 !important;

      .field-radio-wrapper {
        margin-bottom: 0;
      }
    }
  }
}
</style>