<template>
  <div class="dashboard-view">
    <div class="page-wrapper">
      <!--START: Menu-->
      <PageMenu
        :menu="helpdeskMenu"
        :highlightExactRoute="false"
        class="page-menu"
      ></PageMenu>
      <!--END: Menu-->

      <!--START: Settings View-->
      <div v-if="!lodash.isEmpty(widget)" class="page-content">
        <keep-alive
          ><router-view
            @saveWidget="saveWidget"
            @saveCompany="saveCompany"
            :reloadView="reloadView"
          ></router-view
        ></keep-alive>
      </div>
      <!--END: Settings View-->

      <!--START: Pre Loader-->
      <div v-else class="pre-loader-wrapper">
        <PreLoader :show="true" v-for="index in 2" :key="index"></PreLoader>
      </div>
      <!--END: Pre Loader-->

      <!--START: Loader-->
      <LineLoader :show="showOptions.lineLoader"></LineLoader>
      <!--END: Loader-->

      <!--START: Notification Message-->
      <NotificationMessage
        :show="status.show"
        :status="status.status"
        :title="status.title"
      ></NotificationMessage>
      <!--END: Notification Message-->
    </div>
  </div>
</template>
  
  <script>
// Import libraries
import _ from "lodash";

//Importing components
import PageMenu from "@/components/dashboard/PageMenu";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PreLoader from "@/components/loaders/PreLoader";

// Importing Services
import { CompanyService, AIModelService } from "@/services";

export default {
  name: "AIBehavior",
  data() {
    return {
      lodash: _,
      reloadView: false,
      showOptions: {
        lineLoader: false,
      },
      helpdeskMenu: [
        {
          name: "AI Assistant",
          type: "category",
          menuItems: [
            {
              name: "Personas",
              icon: "users-alt",
              path: "/ai-assistant/personas",
            },
            {
              name: "Answer Rules",
              icon: "comment-alt",
              path: "/ai-assistant/answer-rules",
            },
          ],
        },
      ],
      status: {
        show: false,
        status: "success",
        title: "Widget saved",
      },
      saveInProgress: false,
    };
  },
  components: {
    PageMenu,
    NotificationMessage,
    LineLoader,
    PreLoader,
  },
  computed: {
    widget() {
      return this.$store.getters.widget;
    },
  },
  async created() {
    await this.getWidget();
  },
  methods: {
    // Get Widget
    async getWidget() {
      if (_.isEmpty(this.widget)) {
        const { data } = await AIModelService.GetWidget();
        this.constructWidget(data);
      }
    },

    // Save widget
    async saveWidget(widget) {
      if (!this.saveInProgress) {
        this.saveInProgress = true;
        // Hide status message and show line loader
        this.status.show = false;
        this.showOptions.lineLoader = true;

        const response = await AIModelService.SaveAIModel(widget);
        this.constructWidget(response.data);
        this.showStatusMessage(this.status);

        this.reloadView = !this.reloadView;
        this.showOptions.lineLoader = false;
        this.saveInProgress = false;
      }
    },

    // Save company
    async saveCompany(company) {
      // Hide status message and show line loader
      this.status.show = false;
      this.showOptions.lineLoader = true;

      const response = await CompanyService.UpdateCompany(company);
      this.$store.commit("updateCompany", response.data);
      this.showStatusMessage(this.status);

      this.reloadView = !this.reloadView;
      this.showOptions.lineLoader = false;
    },

    // Construct the widget
    constructWidget(data) {
      const widget = {
        _id: data._id,
        companyName: data.companyName,
        companyDescription: data.companyDescription,
        policies: data.policies,
        configurations: data.configurations,
        ...data.widget,
      };

      this.$store.commit("updateWidget", widget);
    },
  },
};
</script>
  
  <style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

/deep/ .page-header {
  margin-bottom: 3rem;
}
/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}
</style>