<template>
  <div class="dashboard-view">
    <div class="page-wrapper">
      <!--START: Menu-->
      <div class="page-menu-wrapper">
        <PageMenu
          :menu="menu"
          :highlightExactRoute="false"
          class="page-menu"
        ></PageMenu>

        <button
          class="btn btn-small btn-primary btn-playground"
          @click="openPlayground"
        >
          <unicon name="bolt"></unicon>
          <span>Test in Playground</span>
        </button>
      </div>
      <!--END: Menu-->

      <!--START: Settings View-->
      <div class="page-content">
        <!--START: Page Loader-->
        <div v-if="showOptions.preLoader" class="pre-loader-wrapper">
          <PreLoader :show="true"></PreLoader>
        </div>
        <!--START: Page Loader-->

        <keep-alive v-else
          ><router-view
            :aiAgent="aiAgent"
            @aiAgentSaved="aiAgentSaved"
          ></router-view
        ></keep-alive>
      </div>
      <!--END: Settings View-->
    </div>

    <!--START: Playground-->
    <TopicPlayground
      :show="showOptions.playground"
      @closeModal="closeModal"
    ></TopicPlayground>
    <div
      class="bg-overlay light-bg"
      :class="{ show: showOptions.playground }"
    ></div>
    <!--END: Playground-->
  </div>
</template>
  
<script>
// Import libraries
import _ from "lodash";

// Importing components
import PageMenu from "@/components/dashboard/PageMenu";
import PreLoader from "@/components/loaders/PreLoader";
import TopicPlayground from "@/components/dashboard/aiAgent/TopicPlayground";

// Importing Services
import { AiAgentService, TopicService } from "@/services";

export default {
  name: "AiAgent",
  data() {
    return {
      aiAgent: {},
      topics: [],
      showOptions: {
        lineLoader: false,
        preLoader: true,
        playground: false,
      },
      menu: [
        {
          id: "tickets-menu",
          type: "category",
          menuItems: [
            {
              name: "Agent activity",
              icon: "inbox",
              path: "/ai-agent/tickets",
              type: "category",
              subMenuType: "accordion",
              menuItems: [
                {
                  name: "All tickets",
                  path: "/ai-agent/tickets#all",
                },
              ],
            },
          ],
        },
        {
          name: "Agent behaviour",
          type: "category",
          menuItems: [
            {
              name: "Persona",
              icon: "users-alt",
              path: "/ai-agent/persona",
            },
            {
              name: "Answer Rules",
              icon: "comment-alt",
              path: "/ai-agent/answer-rules",
            },
          ],
        },
        {
          name: "Ticket handling",
          type: "category",
          menuItems: [
            {
              name: "Ticket Topics",
              icon: "copy-alt",
              path: "/ai-agent/topics",
            },
            {
              name: "Filter Rules",
              icon: "filter",
              path: "/ai-agent/filter-rules",
            },
          ],
        },
        {
          name: "Settings",
          type: "category",
          menuItems: [
            {
              name: "Activation",
              icon: "setting",
              path: "/ai-agent/settings",
            },
          ],
        },
      ],
      status: {
        show: false,
        status: "success",
        title: "AI agent saved",
      },
      saveInProgress: false,
    };
  },
  components: {
    PageMenu,
    PreLoader,
    TopicPlayground,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  async created() {
    this.restrictAdminAccess();
    this.getAiAgent();
    this.getTopics();
  },
  methods: {
    // Get the document
    async getAiAgent() {
      const { hasAccess } = this.company.products.aiAgent;
      if (hasAccess) {
        const response = await AiAgentService.GetAiAgent();

        // Check for errors
        if (!response.hasError) {
          this.aiAgent = response.data;
        } else this.showErrorMessage(this.status, response.message);

        this.showOptions.preLoader = false;
      } else this.redirectDashboard();
    },

    // Update AI agent after it's been saved
    aiAgentSaved(agent) {
      this.aiAgent = _.cloneDeep({});
      this.aiAgent = _.cloneDeep(agent);
    },

    async getTopics() {
      const response = await TopicService.GetTopics();
      if (!response.hasError) {
        this.topics = response.data;
        const menuItem = this.menu.find((item) => item.id === "tickets-menu");
        this.topics.forEach((t) => {
          menuItem.menuItems[0].menuItems.push({
            name: t.title,
            path: `/ai-agent/tickets#${t.title}`,
          });
        });
      }
    },

    // Open the playground
    openPlayground() {
      this.showOptions.playground = true;
    },

    closeModal() {
      this.showOptions.playground = false;
    },
  },
};
</script>
  
<style scoped lang="scss">
@import "@/assets/styles/shared/dashboard.scss";

/deep/ {
  .page-header {
    margin-bottom: 3rem;
  }
  .line-loader {
    position: fixed;
    z-index: 101;
    bottom: 0;
    left: 0;
  }

  .pre-loader {
    margin: 3rem 5%;
  }
}

.page-menu-wrapper {
  position: relative;
  border-right: 1px solid $greyBorderColor;

  &::after {
    position: absolute;
    content: "";
    bottom: 8rem;
    left: 0;
    width: 100%;
    height: 4rem;
    background: linear-gradient(
      to bottom,
      rgba(248, 247, 252, 0) 0%,
      rgba(248, 247, 252, 1) 100%
    );
  }

  /deep/ {
    > .page-menu {
      position: relative;
      border-right: none;
      height: calc(100vh - 5rem - 10rem);
      padding-bottom: 0;
      overflow-y: scroll;
    }
  }

  .btn-playground {
    position: absolute;
    left: 1rem;
    right: 1rem;
    bottom: 3rem;
    margin: auto;
  }
}
</style>