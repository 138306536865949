<template>
  <div class="banner-wrapper" :class="{ 'free-plan': isFreePlan }">
    <span v-if="isFreePlan">
      You're on the free plan with
      <b
        >{{
          this.freePlanLimit - this.subscription.usage.generatedAnswers
        }}
        answers left</b
      >. You can activate a
      <router-link to="/settings/subscriptions">plan here.</router-link></span
    >
    <span v-else
      >You don't have an active subscription. Activate your
      <router-link to="/settings/subscriptions">plan here.</router-link>
    </span>
  </div>
</template>
  
<script>
export default {
  name: "SubscriptionBanner",
  computed: {
    // Get the company
    company() {
      return this.$store.getters.company;
    },

    // Get the current subscription
    subscription() {
      return this.company?.subscription;
    },

    // Check if it's a free plan with answers left
    isFreePlan() {
      if (this.subscription.planType === "free") {
        let answerLimit = this.subscription.answerLimit;
        if (answerLimit === -1) answerLimit = this.freePlanLimit;
        if (this.subscription.usage.generatedAnswers < answerLimit) return true;
      }

      return false;
    },
  },
  data() {
    return {
      freePlanLimit: 50,
    };
  },
};
</script>
  
<style scoped lang="scss">
.banner-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 98;
  width: 100%;
  padding: 0.5rem 0;
  background-color: $redColor;
  text-align: center;

  span {
    font-size: $smallerFontSize;
    color: $whiteColor;
  }

  a {
    color: $whiteColor;
    font-weight: $boldFontWeight;
    text-decoration: underline;
  }

  &.free-plan {
    background-color: $greenMessageBorderColor;

    span,
    a {
      color: darken($greenMessageTextColor, 10%);
    }
  }
}
</style>