<template>
  <div class="page-main flex-page">
    <!--START: Header Wrapper-->
    <div class="header-wrapper">
      <!--START: Page Header-->
      <RegularHeader
        class="regular-header"
        :data="regularHeader"
      ></RegularHeader>
      <!--END: Page Header-->

      <button class="btn btn-small btn-grey" @click="addTicketFilterRule">
        Add Ticket Filter Rule
      </button>
    </div>
    <!--END: Header Wrapper-->

    <!--START: Zero State -->
    <ZeroState
      v-if="showOptions.zeroState"
      class="zero-state"
      :imageURL="zeroStateData.imageURL"
      :title="zeroStateData.title"
      :message="zeroStateData.message"
      :buttonTitle="zeroStateData.buttonTitle"
      @eventHandler="addTicketFilterRule"
    ></ZeroState>
    <!--END: Zero State -->

    <!--START: Answer Rules Table-->
    <TicketFilterRulesTable
      v-else-if="rules"
      :rules="rules"
      @ruleSelected="showRuleModal"
      @deleteRule="deleteRule"
    ></TicketFilterRulesTable>
    <!--END: Answer Rules Table-->

    <!--START: Loader-->
    <PageLoader v-if="!showOptions.initRules" :showLoader="true"></PageLoader>
    <!--END: Loader-->

    <!--START: View Document-->
    <ViewTicketFilterRule
      v-if="showOptions.viewRule"
      :show="showOptions.viewRule"
      :ruleID="selectedRuleID"
      @ruleAdded="ruleAdded"
      @closeModal="closeModal"
    ></ViewTicketFilterRule>
    <div
      class="bg-overlay light"
      :class="{ show: showOptions.viewRule }"
      @click="closeModal"
    ></div>
    <!--END: View Document-->

    <!--START: Notification Message-->
    <NotificationMessage
      :show="status.show"
      :status="status.status"
      :title="status.title"
      @closeModal="closeModal"
    ></NotificationMessage>
    <!--END: Notification Message-->

    <!--START: Loader-->
    <LineLoader :show="showOptions.lineLoader"></LineLoader>
    <!--END: Loader-->
  </div>
</template>
          
<script>
//Import libraries
import _ from "lodash";

// Importing components
import RegularHeader from "@/components/dashboard/RegularHeader";
import ZeroState from "@/components/misc/ZeroState";
import NotificationMessage from "@/components/modals/NotificationMessage";
import LineLoader from "@/components/loaders/LineLoader";
import PageLoader from "@/components/loaders/PageLoader";

import TicketFilterRulesTable from "@/components/dashboard/tables/TicketFilterRulesTable";
import ViewTicketFilterRule from "@/components/dashboard/aiAgent/ViewTicketFilterRule";

// Importing Services
import { TicketFilterRuleService } from "@/services";
import "external-svg-loader";

export default {
  name: "TicketFilterRules",
  data() {
    return {
      showOptions: {
        lineLoader: false,
        initRules: false,
        zeroState: false,
        viewRule: false,
      },
      regularHeader: {
        title: "Filter Rules",
        description:
          "Determine how the AI should handle and filer various tickets",
      },
      zeroStateData: {
        imageURL: "services.jpg",
        title: "No Ticket Filter Rules",
        message:
          "Add filter rules to determine how the AI should filter tickets",
        buttonTitle: "Add Ticket Filter Rule",
      },
      status: {
        show: false,
        status: "success",
        title: "",
      },
      selectedAnswerRule: "",
      rules: [],
      gettingRules: false,
    };
  },
  props: {},
  watch: {},
  components: {
    RegularHeader,
    ZeroState,
    NotificationMessage,
    LineLoader,
    PageLoader,
    TicketFilterRulesTable,
    ViewTicketFilterRule,
  },
  computed: {
    company() {
      return this.$store.getters.company;
    },
  },
  async created() {
    await this.getTicketFilterRules();
  },

  methods: {
    // Delete answe rule
    async deleteRule(ruleID) {
      const data = { ticketFilterRuleID: ruleID };

      await TicketFilterRuleService.DeleteRule(data);

      // Remove from the table
      const index = this.rules.findIndex((r) => r._id == ruleID);
      this.rules.splice(index, 1);

      this.status.title = "Ticket filter rule deleted";
      this.showStatusMessage(this.status, 2500);

      if (this.rules.length === 0) this.showOptions.zeroState = true;
    },

    // When a rule has been added or updated
    ruleAdded(rule) {
      this.selectedRuleID = rule._id;

      let tempRules = _.cloneDeep(this.rules);
      const index = this.rules.findIndex((r) => r._id == rule._id);

      if (index == -1) tempRules.push(rule);
      else tempRules[index] = rule;

      this.rules.splice(0);
      this.rules = _.cloneDeep(tempRules);
      this.showOptions.zeroState = false;
    },

    // Get all the rules
    async getTicketFilterRules() {
      this.gettingRules = true;
      const response = await TicketFilterRuleService.GetAllRules();
      this.rules.push(...response.data);

      if (response.data == 0) this.showOptions.zeroState = true;

      this.gettingRules = false;
      this.showOptions.initRules = true;
    },

    // Show the selected document details
    showRuleModal(ruleID) {
      this.selectedRuleID = ruleID;
      this.showOptions.viewRule = true;
    },

    addTicketFilterRule() {
      this.selectedRuleID = "";
      this.showOptions.viewRule = true;
    },

    // Close all the modals
    closeModal() {
      this.status.show = false;
      this.showOptions.viewRule = false;
    },
  },
};
</script>
          
<style scoped lang="scss">
.header-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1.5rem;

  .regular-header {
    flex: 1;
    max-width: 70%;
    margin-right: 30%;
  }
}

.zero-state {
  margin-top: 2.5rem;
}

.page-loader-wrapper {
  margin-top: 2rem;
}

/deep/ .line-loader {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
}
</style>